import { Button, Typography } from "@mui/material";
import { useState } from "react";
import NumberInput from "Templates/UI/Input/NumberInput";

export default function AddPieceValues(props) {
  const {
    selectedPackageType,
    selectedVehicleType,
    selectedServiceOption,
    addPiece,
    handleClose,
  } = props;
  const [pieceValues, setPieceValues] = useState(undefined);
  return (
    <div>
      <Typography variant="h5" fontWeight={650}>
        Add Piece Surcharge
      </Typography>
      <br />
      <NumberInput
        // defaultValue={}
        // id={}
        // register={register}
        // errors={errors}
        value={pieceValues?.from ?? ""}
        setValue={(e) => {
          setPieceValues((pre) => {
            return {
              ...pre,
              from: e,
            };
          });
        }}
        label={"From (# of pieces)"}
      />
      <br />
      <br />
      <NumberInput
        value={pieceValues?.to ?? ""}
        setValue={(e) => {
          setPieceValues((pre) => {
            return {
              ...pre,
              to: e,
            };
          });
        }}
        label={"To (# of pieces)"}
      />
      <br />
      <br />
      <NumberInput
        value={pieceValues?.price ?? ""}
        setValue={(e) => {
          setPieceValues((pre) => {
            return {
              ...pre,
              price: e,
            };
          });
        }}
        label={"Price"}
      />
      <br />
      <br />
      <NumberInput
        value={pieceValues?.pieces ?? ""}
        setValue={(e) => {
          setPieceValues((pre) => {
            return {
              ...pre,
              pieces: e,
            };
          });
        }}
        label={"Pieces"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginTop: 15,
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{ marginRight: 10 }}
          onClick={async () => {
            if (
              pieceValues?.from &&
              pieceValues?.to &&
              pieceValues?.price &&
              pieceValues?.pieces &&
              parseFloat(pieceValues?.from) < parseFloat(pieceValues?.to)
            ) {
              await addPiece(pieceValues, {
                selectedPackageType,
                selectedServiceOption,
                selectedVehicleType,
              });
              handleClose();
            }
          }}
        >
          Add
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
