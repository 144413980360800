import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useContext, useEffect, useState } from "react";
import AddZoneSet from "./AddZoneSet";
import { getZoneSets, updateZoneSetZone } from "./functions";
import ZoneSetDetails from "./ZoneSetDetails";
import { VishcorpContext } from "Context";
import { deleteZoneSet } from "API/Pricing";

export default function ZoneSets(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const [zoneSets, setZoneSets] = useState(undefined);
  const [selectedZoneSet, setSelectedZoneSet] = useState(undefined);

  const [openZoneSetDialog, setOpenZoneSetDialog] = useState(false);
  const [confirmDeleteZoneSet, setConfirmDeleteZoneSet] = useState(false);

  const refreshZoneSets = async () => {
    await getZoneSets(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        setZoneSets(res);
        if (selectedZoneSet) {
          setSelectedZoneSet(
            res?.find((e) => e?.zoneSetName === selectedZoneSet?.zoneSetName)
          );
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    refreshZoneSets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography>Select a zone set to edit</Typography>
        {/* <Box sx={{ display: "flex", border: 1, height: "100%" }}> */}
        <Paper sx={{ display: "flex", height: "70vh" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Zone Sets</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zoneSets &&
                  zoneSets?.map((e, i) => (
                    <TableRow
                      hover
                      key={i}
                      selected={selectedZoneSet?.zoneSetName === e?.zoneSetName}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedZoneSet(e);
                      }}
                    >
                      <TableCell>{e?.zoneSetName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={(e) => {
              setOpenZoneSetDialog(true);
            }}
          >
            Add Zone Set
          </Button>
          <Button
            variant="contained"
            onClick={() => setConfirmDeleteZoneSet(true)}
          >
            Remove Zone Set
          </Button>
        </div>
        {/* </Box> */}
      </Grid>
      {/* <Grid item xs={1}> */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ marginLeft: 2, height: "100vh" }}
      />
      {/* </Grid> */}
      <Grid item xs={7.5}>
        {selectedZoneSet && (
          <ZoneSetDetails
            zoneSetDetails={selectedZoneSet}
            updateZoneSet={async (e) => {
              await updateZoneSetZone(
                e,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              refreshZoneSets();
            }}
            refreshZoneSets={refreshZoneSets}
            selectedZoneSet={selectedZoneSet?.zoneSetName}
          />
        )}
      </Grid>
      <AlertDialog
        customControls={false}
        open={openZoneSetDialog}
        handleClose={() => setOpenZoneSetDialog(false)}
        dialogContent={
          <AddZoneSet
            zoneSetNames={zoneSets?.map((e) => e?.zoneSetName)}
            onCancel={() => setOpenZoneSetDialog(false)}
            onRefresh={async () => {
              await refreshZoneSets();
            }}
          />
        }
      />
      <AlertDialog
        open={confirmDeleteZoneSet}
        handleClose={() => setConfirmDeleteZoneSet(false)}
        handleConfirm={async () => {
          if (!selectedZoneSet?.zoneSetName) {
            return;
          }
          await deleteZoneSet(
            selectedZoneSet?.zoneSetName,
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          );
          await refreshZoneSets();
          setConfirmDeleteZoneSet(false);
        }}
        dialogTitle={`Delete Zone Set: ${selectedZoneSet?.zoneSetName}?`}
      />
    </Grid>
  );
}
