import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import PeopleIcon from "@mui/icons-material/People";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { GoPackage } from "react-icons/go";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SettingsIcon from "@mui/icons-material/Settings";

export default function Options(props) {
  const [selectedSettings, setSelectedSettings] = React.useState(undefined);

  const { onOptionChanged, selectedOption } = props;

  const handleSelectedSettingsClick = (setting) =>
    setSelectedSettings(selectedSettings === setting ? undefined : setting);

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Settings
        </ListSubheader>
      }
    >
      <ListItemButton
        onClick={() => handleSelectedSettingsClick("Custom Attributes")}
      >
        <ListItemIcon>
          <TuneIcon />
        </ListItemIcon>
        <ListItemText primary="Custom Attributes" />
        {selectedSettings === "Custom Attributes" ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItemButton>
      <Collapse
        in={selectedSettings === "Custom Attributes"}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              onOptionChanged("Customers");
            }}
            selected={selectedOption === "Customers"}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              onOptionChanged("Drivers");
            }}
            selected={selectedOption === "Drivers"}
          >
            <ListItemIcon>
              <DirectionsCarIcon />
            </ListItemIcon>
            <ListItemText primary="Drivers" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              onOptionChanged("Orders");
            }}
            selected={selectedOption === "Orders"}
          >
            <ListItemIcon>
              <GoPackage size={23} />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton
        onClick={() => {
          handleSelectedSettingsClick("Delivery Settings");
          onOptionChanged("Delivery Settings");
        }}
      >
        <ListItemIcon>
          <LocalShippingIcon />
        </ListItemIcon>
        <ListItemText primary="Delivery Settings" />
        {/* {selectedSettings === "Delivery Settings" ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )} */}
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          handleSelectedSettingsClick("Pricing");
          onOptionChanged("Pricing");
        }}
      >
        <ListItemIcon>
          <AttachMoneyIcon />
        </ListItemIcon>
        <ListItemText primary="Pricing" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          handleSelectedSettingsClick("General Settings");
          onOptionChanged("General Settings");
        }}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="General Settings" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          handleSelectedSettingsClick("Global Users");
          onOptionChanged("Global Users");
        }}
      >
        <ListItemText primary="Global Users" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          handleSelectedSettingsClick("Dispatchers");
          onOptionChanged("Dispatchers");
        }}
      >
        <ListItemText primary="Dispatchers" />
      </ListItemButton>
    </List>
  );
}
