import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";

export default function InvoiceOptions(props) {
  const { selectedOption, setSelectedOption } = props;

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Invoices
        </ListSubheader>
      }
    >
      <ListItemButton
        sx={{ width: "100%" }}
        selected={selectedOption === "Customer Invoices"}
        onClick={() => {
          setSelectedOption("Customer Invoices");
        }}
      >
        <ListItemText primary="Customer Invoices" />
      </ListItemButton>
      <ListItemButton
        sx={{ width: "100%" }}
        selected={selectedOption === "Driver Commissions"}
        onClick={() => {
          setSelectedOption("Driver Commissions");
        }}
      >
        <ListItemText primary="Driver Commissions" />
      </ListItemButton>
      <ListItemButton sx={{ width: "100%" }} disabled>
        <ListItemText primary="Sales Report" />
      </ListItemButton>
      <ListItemButton sx={{ width: "100%" }} disabled>
        <ListItemText primary="Performance Report" />
      </ListItemButton>
      <ListItemButton
        sx={{ width: "100%" }}
        selected={selectedOption === "Settings"}
        onClick={() => {
          setSelectedOption("Settings");
        }}
      >
        <ListItemText primary="Settings" />
      </ListItemButton>
    </List>
  );
}
