import { useContext } from "react";
import { VishcorpSettingsContext } from "../context";
import AttributesComponent from "../UI/AttributesComponent";

export default function Customers() {
  const { organizationSettings } = useContext(VishcorpSettingsContext);
  const orderDefaultSettings =
    organizationSettings?.organizationSettings?.orderSettings;

  return <AttributesComponent settings={orderDefaultSettings} label="Order" />;
}
