import { FormLabel, Grid, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import OrderLists from "Components/Orders/OrderLists";
import { VishcorpContext } from "Context";
import EditOrder from "Components/Orders/EditOrder";
import { updateOrder } from "API/Orders";

export default function CompletedOrderList(props) {
  const {
    drivers,
    cancelledOrders,
    completedOrders,
    refreshOrder,
    selectedOrder,
    setSelectedOrder,
    orgSettings
  } = useContext(VishcorpContext);
  const [editOrder, setEditOrder] = useState(false);
  // const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [modifiedStatus, setModifiedStatus] = useState(undefined);
  const [onUpdateOrder, setOnUpdateOrder] = useState(false);
  const [orderData, setOrderData] = useState(undefined);
  const [assignedDriver, setAssignedDriver] = useState(undefined);
  const [alertText, setAlertText] = useState(undefined);

  useEffect(() => {
    if (cancelledOrders && completedOrders) {
      // console.log(completedOrders, cancelledOrders)
      setOrderData([...completedOrders, ...cancelledOrders]);
    }
  }, [cancelledOrders, completedOrders]);

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={6}>
        <FormLabel sx={{ fontSize: "2rem" }}>Completed Orders</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <OrderLists
          orders={orderData}
          onEditClick={(e) => {
            // setEditOrder(true);
            setSelectedOrder(e);
            setModifiedStatus(e?.orderStatus);
            setAssignedDriver(e?.assignedDriver);
          }}
          activeOrder
        />
      </Grid>
      {editOrder && (
        <AlertDialog
          open={editOrder}
          // open={editOrder}
          handleClose={() => {
            setEditOrder(false);
          }}
          customControls={false}
          dialogContent={
            <Grid sx={{ position: "static" }}>
              <FormLabel sx={{ fontSize: "2rem" }}>Edit Order</FormLabel>
              <br />
              <br />
              <EditOrder
                selectedOrder={selectedOrder}
                onDriverAssigned={(e) => setAssignedDriver(e)}
                onStatusChange={(e) => setModifiedStatus(e)}
                activeOrder
              />
              <br />
              <Grid
                container
                sx={{
                  display: "flex",
                  // justifyContent: "right",
                  // alignContent: "right",
                }}
                spacing={1}
              >
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    onClick={() => {
                      if (
                        modifiedStatus !== selectedOrder?.orderStatus ||
                        assignedDriver !== selectedOrder?.assignedDriver
                      ) {
                        setOnUpdateOrder(true);
                      }
                      if (modifiedStatus !== selectedOrder?.orderStatus) {
                        setAlertText(
                          `Status Changed: ${selectedOrder?.orderStatus} -> ${modifiedStatus}`
                        );
                      }
                      if (assignedDriver !== selectedOrder?.assignedDriver) {
                        setAlertText(
                          `Status Changed: ${
                            drivers?.find(
                              (e) =>
                                e?.driverNumber ===
                                selectedOrder?.assignedDriver
                            )?.driverName
                          } -> ${
                            drivers?.find(
                              (e) => e?.driverNumber === assignedDriver
                            )?.driverName
                          }`
                        );
                      }
                    }}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setEditOrder(false);
                      setSelectedOrder(undefined);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
      <AlertDialog
        open={onUpdateOrder}
        handleClose={() => {
          setOnUpdateOrder(false);
        }}
        handleConfirm={() => {
          updateOrder(
            selectedOrder,
            modifiedStatus,
            assignedDriver,
            orgSettings?.organizationTablePrefix
          ).then((res) => {
            refreshOrder();
            console.log("Status Updated: ", res);
          });
          setEditOrder(false);
          setOnUpdateOrder(false);
        }}
        dialogTitle={
          <b>
            Confirm Update.
            <br />
            {`Order: ${selectedOrder?.orderID}.`}
          </b>
        }
        dialogContent={<b>{alertText}</b>}
      />
    </Grid>
  );
}
