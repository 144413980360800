export const addGlobalUser = async (
  globalUserDetails,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "ADD_GLOBAL_USER",
        globalUserDetails,
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getGlobalUsers = async (
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_GLOBAL_USERS",
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response?.Items)
    .catch(console.error);
};
