import { FormLabel, Grid, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import EditOrder from "./EditOrder";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import OrderLists from "./OrderLists";
import { VishcorpContext } from "Context";
import RightClickMenu from "Components/RightClickMenu";
import Optimize from "Components/Optimize";
import { updateDriverOptimizedOrder } from "API/Drivers";
import { optimizeWayPoints, updateOrder } from "API/Orders";

export default function OrdersHome(props) {
  const {
    refreshDrivers,
    drivers,
    activeOrders,
    cancelledOrders,
    completedOrders,
    confirmedOrders,
    refreshOrder,
    selectedOrder,
    setSelectedOrder,
    allOrders,
    setAllOrders,
    selectedDriver,
    isOptimized,
    setIsOptimized,
    orgSettings,
  } = useContext(VishcorpContext);
  const [editOrder, setEditOrder] = useState(false);
  // const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [modifiedStatus, setModifiedStatus] = useState(undefined);
  const [onUpdateOrder, setOnUpdateOrder] = useState(false);
  const orderType = "all";
  // const [orderType, setOrderType] = useState("all");
  const [orderData, setOrderData] = useState(undefined);
  const [assignedDriver, setAssignedDriver] = useState(undefined);
  const [alertText, setAlertText] = useState(undefined);
  const [contextMenu, setContextMenu] = useState(undefined);
  const [openOptimizeDialog, setOpenOptimizeDialog] = useState(false);
  const [optimizeType, setOptimizeType] = useState(false);
  const driverInfo = drivers?.find((e) => e?.driverNumber === selectedDriver);

  useEffect(() => {
    switch (orderType) {
      case "active":
        setOrderData(activeOrders);
        break;
      case "cancelled":
        setOrderData(cancelledOrders);
        break;
      case "completed":
        setOrderData(completedOrders);
        break;
      case "confirmed":
        setOrderData(confirmedOrders);
        break;
      case "all":
        setOrderData(allOrders);
        break;
      default:
        break;
    }
  }, [
    orderType,
    activeOrders,
    cancelledOrders,
    completedOrders,
    confirmedOrders,
    allOrders,
  ]);

  return (
    <Grid
      container
      spacing={2}
      mt={1}
      onContextMenu={(e) => {
        e.preventDefault();
        setContextMenu(
          contextMenu === undefined && e !== undefined
            ? {
                // mouseX: e.pageX + 2,
                mouseX: e.pageX,
                mouseY: e.pageY,
                // mouseY: e.pageY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              undefined
        );
      }}
    >
      <Grid item xs={2}>
        <FormLabel sx={{ fontSize: "2rem" }}>Orders</FormLabel>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          // marginTop: 2,
          // alignItems: "center",
          // height: 20,
        }}
      >
        {isOptimized ? (
          <FormLabel sx={{ fontSize: "1em" }}>
            <b>
              Optimized
              <br />
            </b>{" "}
            <b>Start:</b>{" "}
            {driverInfo?.driverOptimizedOrders?.startAddress?.address ?? "None"}
            <br />
            <b>End:</b>{" "}
            {driverInfo?.driverOptimizedOrders?.endAddress?.address ?? "None"}
          </FormLabel>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <OrderLists
          orders={orderData}
          onEditClick={(e) => {
            // setEditOrder(true);
            setSelectedOrder(e);
            setModifiedStatus(e?.orderStatus);
            setAssignedDriver(e?.assignedDriver);
          }}
          activeOrder
        />
      </Grid>
      {editOrder && (
        <AlertDialog
          open={editOrder}
          // open={editOrder}
          handleClose={() => {
            setEditOrder(false);
          }}
          customControls={false}
          dialogContent={
            <Grid sx={{ position: "static" }}>
              <FormLabel sx={{ fontSize: "2rem" }}>Edit Order</FormLabel>
              <br />
              <br />
              <EditOrder
                selectedOrder={selectedOrder}
                onDriverAssigned={(e) => setAssignedDriver(e)}
                onStatusChange={(e) => setModifiedStatus(e)}
                activeOrder
              />
              <br />
              <Grid
                container
                sx={{
                  display: "flex",
                  // justifyContent: "right",
                  // alignContent: "right",
                }}
                spacing={1}
              >
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    onClick={() => {
                      if (
                        modifiedStatus !== selectedOrder?.orderStatus ||
                        assignedDriver !== selectedOrder?.assignedDriver
                      ) {
                        setOnUpdateOrder(true);
                      }
                      if (modifiedStatus !== selectedOrder?.orderStatus) {
                        setAlertText(
                          `Status Changed: ${selectedOrder?.orderStatus} -> ${modifiedStatus}`
                        );
                      }
                      if (assignedDriver !== selectedOrder?.assignedDriver) {
                        setAlertText(
                          `Status Changed: ${
                            drivers?.find(
                              (e) =>
                                e?.driverNumber ===
                                selectedOrder?.assignedDriver
                            )?.driverName
                          } -> ${
                            drivers?.find(
                              (e) => e?.driverNumber === assignedDriver
                            )?.driverName
                          }`
                        );
                      }
                    }}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setEditOrder(false);
                      setSelectedOrder(undefined);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
      <AlertDialog
        open={onUpdateOrder}
        handleClose={() => {
          setOnUpdateOrder(false);
        }}
        handleConfirm={() => {
          updateOrder(
            selectedOrder,
            modifiedStatus,
            assignedDriver,
            orgSettings?.organizationTablePrefix
          ).then((res) => {
            refreshOrder();
            console.log("Status Updated: ", res);
          });
          setEditOrder(false);
          setOnUpdateOrder(false);
        }}
        dialogTitle={
          <b>
            Confirm Update.
            <br />
            {`Order: ${selectedOrder?.orderID}.`}
          </b>
        }
        dialogContent={<b>{alertText}</b>}
      />
      <RightClickMenu
        contextMenu={contextMenu}
        setContextMenu={setContextMenu}
        handleOptimize={(type) => {
          setOptimizeType(type);
          if (contextMenu) {
            setOpenOptimizeDialog(true);
            setContextMenu(undefined);
          }
        }}
      />
      <AlertDialog
        customControls={false}
        open={openOptimizeDialog}
        dialogContent={
          <Optimize
            handleClose={() => setOpenOptimizeDialog(false)}
            onOptimize={async (startAddress, endAddress) => {
              console.log({
                action: "OPTIMIZE_WAY_POINTS",
                source: {
                  latitude: startAddress?.latitude,
                  longitude: startAddress?.longitude,
                },
                destinations: allOrders
                  ?.filter((e) => e?.orderStatus !== "Active")
                  ?.map((e) => ({
                    latitude:
                      optimizeType === "Pickup"
                        ? e?.pickupDetails?.latitude
                        : e?.deliveryDetails?.latitude,
                    longitude:
                      optimizeType === "Pickup"
                        ? e?.pickupDetails?.longitude
                        : e?.deliveryDetails?.longitude,
                  })),
                endPoint: {
                  latitude: endAddress?.latitude,
                  longitude: endAddress?.longitude,
                },
              });
              await optimizeWayPoints(
                allOrders,
                startAddress,
                endAddress,
                optimizeType,
                orgSettings?.organizationTablePrefix
              )
                .then(async (res) => {
                  let dump = res?.optimizeResponse?.optimizedOrder;
                  try {
                    if (startAddress?.latitude) {
                      dump.shift();
                      dump = dump.map((e) => e - 1);
                    }
                    if (endAddress?.latitude) {
                      dump.pop();
                    }
                    const tempAllOrders = allOrders?.filter(
                      (g) => g?.orderStatus !== "Active"
                    );
                    dump = dump.map((e) => {
                      return tempAllOrders?.[e]?.orderID;
                    });
                    await updateDriverOptimizedOrder(
                      drivers?.find((e) => e?.driverNumber === selectedDriver)
                        ?.driverID,
                      {
                        optimizedOrders: dump,
                        startAddress,
                        endAddress,
                      },
                      orgSettings?.organizationTablePrefix
                    );
                    await refreshDrivers();
                    setIsOptimized(true);
                    setAllOrders((pre) => [
                      ...dump.map((e) => {
                        return pre?.find((a) => a?.orderID === e);
                      }),
                      ...pre?.filter((e) => e?.orderStatus === "Active"),
                    ]);
                    // setOptimizedOrder(res);
                    // saveDriverOptimizedOrder
                  } catch (error) {}
                })
                .catch(console.error);
              setOpenOptimizeDialog(false);
            }}
          />
        }
        dialogTitle={`Optimize ${optimizeType}`}
      />
    </Grid>
  );
}
