import { Alert, Button, Snackbar, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { addZoneSet } from "./functions";
import { VishcorpContext } from "Context";

export default function AddZoneSet(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const { onCancel, onRefresh, zoneSetNames } = props;
  const [zoneSetName, setZoneSetName] = useState("");
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  return (
    <div>
      <Typography>Add New Zone</Typography>
      <br />
      <TextField
        fullWidth
        label="Zone Name"
        value={zoneSetName}
        onChange={(e) => setZoneSetName(e?.target?.value)}
      />
      <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
        <Button
          variant="contained"
          size="small"
          style={{ marginRight: 5 }}
          onClick={async () => {
            if (!zoneSetName) {
              return;
            }
            if (
              zoneSetNames?.findIndex(
                (e) => String(e) === String(zoneSetName)
              ) === -1 ||
              zoneSetNames?.findIndex(
                (e) => String(e) === String(zoneSetName)
              ) === undefined
            ) {
              await addZoneSet(
                zoneSetName,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              await onRefresh();
              onCancel();
            } else {
              setOpenAlertMessage(true);
            }
          }}
        >
          Save
        </Button>
        <Button variant="contained" size="small" onClick={onCancel}>
          Cancel
        </Button>
      </div>
      <Snackbar
        open={openAlertMessage}
        autoHideDuration={6000}
        onClose={() => {
          setOpenAlertMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenAlertMessage(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Zone Set already exists.
        </Alert>
      </Snackbar>
    </div>
  );
}
