// import NewServiceOption from "./NewServiceOption";
import { Grid, Paper } from "@mui/material";
import PackageType from "./PackageType";
import ServiceOptions from "./ServiceOptions";
import VehicleType from "./VehicleType";

export default function DeliverySettings(props) {
  return (
    <Paper style={{ margin: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ServiceOptions />
        </Grid>
        <Grid item xs={6}>
          <PackageType />
        </Grid>
        <Grid item xs={6}>
          <VehicleType />
        </Grid>
      </Grid>
    </Paper>
  );
}
