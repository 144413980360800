import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import AddressInput from "Templates/UI/Input/AddressInput";
import Phone from "Templates/UI/Input/Phone";

export default function Optimize(props) {
  const { handleClose, onOptimize } = props;
  const [startAddress, setStartAddress] = useState({
    address: "",
    city: "",
    state: "",
    postCode: "",
    phone: "",
    company: "",
    contact: "",
  });
  const [endAddress, setEndAddress] = useState({
    address: "",
    city: "",
    state: "",
    postCode: "",
    phone: "",
    company: "",
    contact: "",
  });

  return (
    <div style={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            style={{ marginBottom: 5 }}
            label="Company"
            fullWidth
            size="small"
            onChange={(e) => {}}
            value={""}
          />
          <AddressInput
            //   defaultValue={defaultValue}
            //  id={id}
            //   errors={errors}
            value={startAddress}
            setValue={(e) => {
              setStartAddress(e);
            }}
            label={"Address"}
            //   required={required}
          />
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                style={{ marginBottom: 5 }}
                label="City"
                fullWidth
                size="small"
                // onChange={(e) => {}}
                value={startAddress?.city ?? ""}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>State</InputLabel>
                <Select label="State" size="small" value={startAddress?.state}>
                  <MenuItem value={"Victoria"}>Vic</MenuItem>
                  <MenuItem value={"South Australia"}>SA</MenuItem>
                  <MenuItem value={"Australian Capital Territory"}>
                    ACT
                  </MenuItem>
                  <MenuItem value={"Northern Territory"}>NT</MenuItem>
                  <MenuItem value={"Queensland"}>Qld</MenuItem>
                  <MenuItem value={"Tasmania"}>Tas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                style={{ marginBottom: 5 }}
                label="Postal Code"
                fullWidth
                size="small"
                // onChange={(e) => {}}
                value={startAddress?.postCode ?? ""}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                style={{ marginBottom: 5 }}
                label="Country"
                fullWidth
                size="small"
                onChange={(e) => {}}
                value={"Australia"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                style={{ marginBottom: 5 }}
                label="Contact"
                fullWidth
                size="small"
                onChange={(e) => {}}
                value={""}
              />
            </Grid>
            <Grid item xs={6}>
              <Phone
                value={startAddress?.phone}
                setValue={(e) =>
                  setStartAddress((pre) => ({ ...pre, phone: e }))
                }
                label={"Phone"}
                //   required={required}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            style={{ marginBottom: 5 }}
            label="Company"
            fullWidth
            size="small"
            onChange={(e) => {}}
            value={""}
          />
          <AddressInput
            //   defaultValue={defaultValue}
            //  id={id}
            //   errors={errors}
            value={endAddress}
            setValue={setEndAddress}
            label={"Address"}
            //   required={required}
          />
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                style={{ marginBottom: 5 }}
                label="City"
                fullWidth
                size="small"
                // onChange={(e) => {}}
                value={endAddress?.city ?? ""}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>State</InputLabel>
                <Select label="State" size="small" value={endAddress?.state}>
                  <MenuItem value={10}>Vic</MenuItem>
                  <MenuItem value={20}>SA</MenuItem>
                  <MenuItem value={30}>ACT</MenuItem>
                  <MenuItem value={30}>NT</MenuItem>
                  <MenuItem value={30}>Qld</MenuItem>
                  <MenuItem value={30}>Tas</MenuItem>
                  <MenuItem value={30}>NT</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                style={{ marginBottom: 5 }}
                label="Postal Code"
                fullWidth
                size="small"
                // onChange={(e) => {}}
                value={endAddress?.postCode ?? ""}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                style={{ marginBottom: 5 }}
                label="Country"
                fullWidth
                size="small"
                onChange={(e) => {}}
                value={"Australia"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                style={{ marginBottom: 5 }}
                label="Contact"
                fullWidth
                size="small"
                onChange={(e) => {}}
                value={""}
              />
            </Grid>
            <Grid item xs={6}>
              <Phone
                value={endAddress?.phone}
                setValue={(e) => setEndAddress((pre) => ({ ...pre, phone: e }))}
                label={"Phone"}
                //   required={required}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Button
          style={{ marginRight: 5 }}
          variant="contained"
          onClick={() => {
            onOptimize(startAddress, endAddress);
          }}
        >
          Optimize
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
