import RGL, { WidthProvider } from "react-grid-layout";
// import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import React, { useState } from "react";
// import Orders from "Components/Orders/Orders";
import Drivers from "Components/Drivers/Drivers";
import IncomingOrders from "Components/Orders/IncomingOrders";
import OrdersHome from "Components/Orders/OrdersHome";
import "react-grid-layout/css/styles.css";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import { Add, Close, People, DirectionsCar } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import Customers from "Components/Customers/Customers";
// import InfoPanel from "Components/InfoPanel";
import OrderInfo from "Components/InfoPanel/OrderInfo";
import DriverPanel from "Components/DriverPanel.js";

const ResponsiveGridLayout = WidthProvider(RGL);
export default function AppTemplate(props) {
  // const [hideSidebar, setHideSidebar] = useState(false);
  const hideSidebar = false;
  const [layout, setLayout] = useState([
    {
      i: "1-left",
      x: 2,
      y: 0,
      w: 6,
      h: 13,
      minH: 6,
      isDraggable: false,
      isResizable: true,
      resizeHandles: ["se", "e", "s"],
    },
    {
      i: "2-right",
      x: 8,
      y: 0,
      w: 4,
      h: 26,
      isDraggable: false,
      isResizable: true,
      // resizeHandles: ["w"],
    },
    {
      i: "3-left",
      x: 2,
      y: 18,
      w: 6,
      h: 13,
      minH: 6,
      isDraggable: false,
      isResizable: true,
      resizeHandles: ["se", "e", "s"],
    },
    {
      i: "4-first",
      x: 0,
      y: 0,
      w: 2,
      h: 26,
      isDraggable: false,
      isResizable: false,
      // resizeHandles: ["se", "e", "s"],
    },
  ]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(false);

  const handleLayoutChange = (pLayout, newVal) => {
    const changeIndex = newVal?.i;
    const changeValue = newVal?.w;
    // const group = newVal?.i?.split("-")?.[1];

    const first = pLayout?.find((e) => e?.i === "4-first");
    const oneLeft = pLayout?.find((e) => e?.i === "1-left");
    const threeLeft = pLayout?.find((e) => e?.i === "3-left");
    const twoLeft = pLayout?.find((e) => e?.i === "2-right");

    console.log(pLayout, newVal, 12 - first?.w - newVal?.w);

    let newLayout;
    switch (changeIndex) {
      case "1-left":
        newLayout = [
          { ...first },
          { ...newVal },
          { ...threeLeft, w: changeValue },
          {
            ...twoLeft,
            w: 12 - first?.w - newVal?.w,
            x: newVal?.x + changeValue,
          },
        ];
        break;
      case "2-right":
        newLayout = [
          { ...first },
          { ...oneLeft, w: 12 - first?.w - newVal?.w },
          { ...threeLeft, w: 12 - first?.w - newVal?.w },
          { ...newVal },
        ];
        break;
      case "3-left":
        newLayout = [
          { ...first },
          { ...oneLeft, w: changeValue },
          { ...newVal },
          {
            ...twoLeft,
            w: 12 - first?.w - newVal?.w,
            x: newVal?.x + changeValue,
          },
        ];
        break;
      default:
        break;
    }

    // let newLayout = pLayout?.map((e) => {
    //   if (e?.i !== changeIndex && group !== e?.i?.split("-")?.[1]) {
    //     switch (e?.i?.split("-")?.[1]) {
    //       case "right":
    //         return {
    //           ...e,
    //           w: 12 - changeValue,
    //           x: changeValue,
    //           isDraggable: false,
    //           isResizable: true,
    //         };
    //       default:
    //         return {
    //           ...e,
    //           w: 12 - changeValue,
    //           isDraggable: false,
    //           isResizable: true,
    //         };
    //     }
    //   } else if (e?.i !== changeIndex && group === e?.i?.split("-")?.[1]) {
    //     return { ...e, w: changeValue };
    //   }
    //   switch (e?.i?.split("-")?.[1]) {
    //     case "right":
    //       return {
    //         ...e,
    //         w: changeValue,
    //         x: 12 - changeValue,
    //         isDraggable: false,
    //         isResizable: true,
    //       };
    //     default:
    //       return { ...e, isDraggable: false, isResizable: true };
    //   }
    // });

    // let firstEle = newLayout?.find((e) => e?.i === "1-left");

    // if (firstEle?.w >= 11) {
    //   setHideSidebar(true);
    // } else {
    //   setHideSidebar(false);
    // }
    setLayout(newLayout);
  };
  // const toggleFullWidthDesign = () => {
  //   setLayout([
  //     {
  //       i: "1-left",
  //       x: 0,
  //       y: 0,
  //       w: 12,
  //       h: 13,
  //       isDraggable: false,
  //       isResizable: true,
  //       resizeHandles: ["se", "e", "s"],
  //     },
  //     {
  //       i: "3-left",
  //       x: 0,
  //       y: 18,
  //       w: 12,
  //       h: 13,
  //       isDraggable: false,
  //       isResizable: true,
  //       resizeHandles: ["se", "e", "s"],
  //     },
  //   ]);
  // };
  return (
    <div>
      <ResponsiveGridLayout
        // width={1600}
        layout={layout}
        measureBeforeMount={true}
        className="layout"
        rowHeight={30}
        items={4}
        onResize={function (resizeProps, preVal, newVal) {
          // if (newVal?.minH <= newVal?.h || !newVal?.minH) {
          handleLayoutChange(resizeProps, newVal);
          // }
        }}
        onLayoutChange={(pLayout) => {
          // handleLayoutChange(pLayout, pLayout[0]);
          // if (!hideSidebar) {
          //   const rightElement = pLayout?.find((e) => e?.i === "2-right");
          //   if (rightElement?.x === 0) {
          //     handleLayoutChange(pLayout, pLayout[0]);
          //   }
          // } else {
          //   const firstElement = pLayout?.find((e) => e?.i === "1-left");
          //   if (firstElement?.w !== 12) {
          //     toggleFullWidthDesign();
          //   }
          // }
        }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        margin={[0, 0]}
        // cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        cols={12}
      >
        <div
          key="4-first"
          className="grid-item"
          style={{ background: "#e6e6e6", padding: 2 }}
        >
          <DriverPanel />
        </div>
        <div
          key="1-left"
          className="grid-item"
          style={{ overflow: "scroll", background: "#e6e6e6", padding: 2 }}
        >
          <OrdersHome />
        </div>
        {!hideSidebar && (
          <div
            key="2-right"
            className="grid-item"
            style={{ overflow: "scroll", background: "#E2F3FB", padding: 8 }}
          >
            <OrderInfo />
            {/* <Drivers /> */}
          </div>
        )}
        <div
          key="3-left"
          className="grid-item"
          style={{ overflow: "scroll", background: "#e6e6e6", padding: 2 }}
        >
          <IncomingOrders />
        </div>
      </ResponsiveGridLayout>
      <FloatingMenu
        slideSpeed={500}
        direction="up"
        spacing={8}
        isOpen={menuOpen}
        style={{ position: "fixed", bottom: 10, right: 10 }}
      >
        <MainButton
          iconResting={<Add style={{ fontSize: 20 }} />}
          iconActive={<Close style={{ fontSize: 20 }} />}
          backgroundColor="black"
          onClick={() => setMenuOpen((preVal) => !preVal)}
          size={56}
        />
        <ChildButton
          icon={<People style={{ fontSize: 20 }} />}
          size={40}
          onClick={() => {
            setDialogComponent(<Customers />);
            setOpenDialog(true);
          }}
        />
        <ChildButton
          icon={<DirectionsCar style={{ fontSize: 20 }} />}
          size={40}
          onClick={() => {
            setDialogComponent(<Drivers />);
            setOpenDialog(true);
          }}
        />
      </FloatingMenu>
      <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
        {dialogComponent ?? ""}
      </Dialog>
    </div>
  );
}
