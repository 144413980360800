import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useState } from "react";
import AddCompany from "./AddCompany";

export default function IncludedCompanies(props) {
  const { onAddCompany, onRemoveCompany, companies } = props;
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [openAddCompany, setOpenAddCompany] = useState(undefined);
  return (
    <div>
      <Typography>Included Companies</Typography>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenAddCompany(true)}
        >
          Add Company
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (!selectedCompany) {
              return;
            }
            onRemoveCompany(selectedCompany);
          }}
        >
          Remove
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies &&
              companies?.map((e) => (
                <TableRow
                  hover
                  selected={selectedCompany === e?.companyName}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedCompany(e?.companyName);
                  }}
                >
                  <TableCell>{e?.companyName}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        customControls={false}
        open={openAddCompany}
        handleClose={() => setOpenAddCompany(false)}
        dialogContent={
          <AddCompany
            onCancel={() => setOpenAddCompany(false)}
            onSave={(companyName) => {
              onAddCompany(companyName);
            }}
            onRefresh={async () => {
              // await refreshZoneSets();
            }}
          />
        }
      />
    </div>
  );
}
