import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Close } from "@mui/icons-material";
import { VishcorpPricingContext } from "./context";
import { getZoneSet } from "../functions";
import { VishcorpContext } from "Context";

export default function ZoneBased(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const { pricingLists } = useContext(VishcorpPricingContext);
  const {
    pricingList,
    serviceOptions,
    setSelectedPricingList,
    onSavePriceList,
  } = props;
  const [editCell, setEditCell] = useState(undefined);
  const [editCellValue, setEditCellValue] = useState(undefined);
  const [zoneSet, setZoneSet] = useState(undefined);

  useEffect(() => {
    if (pricingList?.pricingListDetails?.zoneSetName === undefined) {
      return;
    }

    getZoneSet(
      pricingList?.pricingListDetails?.zoneSetName,
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => setZoneSet(res))
      .catch(console.error);
  }, [pricingList, orgSettings]);

  // const [serviceOptions, setServiceOptions] = useState(undefined);
  const onSaveEdit = () => {
    if (editCell?.zoneName && editCell?.serviceOption) {
      let zoneData = pricingList?.pricingListDetails?.zonePricingList?.find(
        (e) => e?.zoneName === editCell?.zoneName
      );
      if (!zoneData) {
        setSelectedPricingList((pre) => ({
          ...pre,
          pricingListDetails: {
            ...pre?.pricingListDetails,
            zonePricingList: [
              ...pre?.pricingListDetails?.zonePricingList,
              {
                zoneName: editCell?.zoneName,
                [editCell?.serviceOption]: parseFloat(editCellValue),
              },
            ],
          },
        }));
      } else {
        setSelectedPricingList((pre) => ({
          ...pre,
          pricingListDetails: {
            ...pre?.pricingListDetails,
            zonePricingList: pre?.pricingListDetails?.zonePricingList?.map(
              (e) => {
                if (e?.zoneName === editCell?.zoneName) {
                  return {
                    ...e,
                    [editCell?.serviceOption]: parseFloat(editCellValue),
                  };
                }
                return e;
              }
            ),
          },
        }));
      }

      setEditCell(undefined);
    }
  };

  return (
    <Grid container spacing={2} style={{ marginLeft: 5 }}>
      <Grid item xs={6}>
        <Typography variant="h5">
          Zone Sheets: {pricingList?.pricingListName}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5">
          From Zone:{" "}
          {pricingList?.pricingListDetails?.zones?.[0]?.zoneName ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          style={{ width: "100%", marginRight: 10 }}
          size="small"
        >
          <InputLabel>Rollover Pricelist:</InputLabel>
          <Select
            fullWidth
            size="small"
            label="Rollover Pricelist:"
            value={pricingList?.pricingListDetails?.backUpPricingList ?? ""}
            onChange={(e) => {
              setSelectedPricingList((pre) => ({
                ...(pre ?? {}),
                pricingListDetails: {
                  ...(pre?.pricingListDetails ?? {}),
                  backUpPricingList: e?.target?.value,
                },
              }));
            }}
          >
            {pricingLists?.length > 0 &&
              pricingLists
                ?.filter(
                  (e) => e?.pricingListName !== pricingList?.pricingListName
                )
                ?.map((e, i) => (
                  <MenuItem key={i} value={e?.pricingListName}>
                    {e?.pricingListName}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ height: "70vh" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Zone</b>
                </TableCell>
                {serviceOptions?.length > 0 &&
                  serviceOptions?.map((e, i) => (
                    <TableCell key={i}>
                      <b>{e?.name}</b>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {zoneSet?.zones?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.zoneName}</TableCell>
                  {serviceOptions?.length > 0 &&
                    serviceOptions?.map((f, j) => {
                      if (
                        editCell?.zoneName === e?.zoneName &&
                        editCell?.serviceOption === f?.name
                      ) {
                        return (
                          <TableCell key={j}>
                            <TextField
                              size="small"
                              style={{ width: "60%" }}
                              value={editCellValue ?? ""}
                              onChange={(g) => {
                                const regex = /^(\d)*(\.)?([0-9]{0,2})?$/;
                                if (
                                  g.target.value === "" ||
                                  regex.test(g.target.value)
                                ) {
                                  setEditCellValue(g?.target?.value);
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => onSaveEdit()}>
                                      <DoneIcon style={{ color: "blue" }} />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setEditCell(undefined);
                                      }}
                                    >
                                      <Close style={{ color: "red" }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={j}
                          onDoubleClick={() => {
                            setEditCell({
                              zoneName: e?.zoneName,
                              serviceOption: f?.name,
                            });
                            setEditCellValue(
                              pricingList?.pricingListDetails?.zonePricingList?.find(
                                (p) => e?.zoneName === p?.zoneName
                              )?.[f?.name] !== undefined
                                ? pricingList?.pricingListDetails?.zonePricingList?.find(
                                    (p) => e?.zoneName === p?.zoneName
                                  )?.[f?.name]
                                : ""
                            );
                          }}
                        >
                          {pricingList?.pricingListDetails?.zonePricingList?.find(
                            (p) => e?.zoneName === p?.zoneName
                          )?.[f?.name] !== undefined
                            ? `$ ${
                                pricingList?.pricingListDetails?.zonePricingList?.find(
                                  (p) => e?.zoneName === p?.zoneName
                                )?.[f?.name]
                              }`
                            : ""}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))}
              {/* {pricingList?.pricingListDetails?.zonePricingList?.length >= 0 &&
                pricingList?.pricingListDetails?.zonePricingList?.map(
                  (e, i) => (
                    <TableRow key={i}>
                      <TableCell>{e?.zoneName}</TableCell>
                      {serviceOptions?.length > 0 &&
                        serviceOptions?.map((f, j) => {
                          if (
                            editCell?.zoneName === e?.zoneName &&
                            editCell?.serviceOption === f?.name
                          ) {
                            return (
                              <TableCell key={j}>
                                <TextField
                                  size="small"
                                  style={{ width: "60%" }}
                                  value={editCellValue ?? ""}
                                  onChange={(g) => {
                                    const regex = /^(\d)*(\.)?([0-9]{1})?$/;
                                    if (
                                      g.target.value === "" ||
                                      regex.test(g.target.value)
                                    ) {
                                      setEditCellValue(g?.target?.value);
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => onSaveEdit()}
                                        >
                                          <DoneIcon style={{ color: "blue" }} />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            setEditCell(undefined);
                                          }}
                                        >
                                          <Close style={{ color: "red" }} />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              key={j}
                              onDoubleClick={() => {
                                setEditCell({
                                  zoneName: e?.zoneName,
                                  serviceOption: f?.name,
                                });
                              }}
                            >
                              {e?.[f?.name] !== undefined
                                ? `$ ${e?.[f?.name]}`
                                : ""}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  )
                )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => {
            onSavePriceList();
          }}
        >
          Save
        </Button>
        <Button variant="contained">Cancel</Button>
      </Grid>
    </Grid>
  );
}
