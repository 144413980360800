import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function GlobalUserList(props) {
  const { globalUsers } = props;
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Global User Name</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Address</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {globalUsers?.length > 0 ? (
              globalUsers?.map((e) => (
                <TableRow>
                  <TableCell>{e?.globalUserName}</TableCell>
                  <TableCell>{e?.email}</TableCell>
                  <TableCell>{e?.address?.address}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>No Records</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
