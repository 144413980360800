import {
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Button,
} from "@mui/material";
import { invoiceOptions } from "./constants";
import { useEffect, useState } from "react";
import AlertDialog from "Components/AlertDialog/AlertDialog";

export default function CustomizeCustomerInvoice(props) {
  const { handleClose, updateCustomerInvoiceSettings } = props;
  const [customerInvoiceSettings, setCustomerInvoiceSettings] =
    useState(undefined);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);

  useEffect(() => {
    setCustomerInvoiceSettings(props?.customerInvoiceSettings);
  }, [props]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Order #</b>
              </TableCell>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Pickup At</b>
              </TableCell>
              <TableCell>
                <b>Deliver To</b>
              </TableCell>
              <TableCell colSpan={2}>
                <b>Details</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>ORD #</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>
                COMPANY NAME
                <br />
                STREET ADDRESS
                <br />
                CITY, STATE
                <br />
                POSTAL/ZIP, COUNTRY
              </TableCell>
              <TableCell>
                COMPANY NAME
                <br />
                STREET ADDRESS
                <br />
                CITY, STATE
                <br />
                POSTAL/ZIP, COUNTRY
              </TableCell>
              <TableCell>
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.firstOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      firstOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.secondOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      secondOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.thirdOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      thirdOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.fourthOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      fourthOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.fifthOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      fifthOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.sixthOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      sixthOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.seventhOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      seventhOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 1, width: 200 }}
                  value={customerInvoiceSettings?.eighthOption ?? ""}
                  onChange={(e) => {
                    setCustomerInvoiceSettings((pre) => ({
                      ...pre,
                      eighthOption: e?.target?.value,
                    }));
                  }}
                >
                  {invoiceOptions?.map((e) => (
                    <MenuItem key={e?.key} value={e?.key}>
                      {e?.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>AMOUNT</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button
          variant="contained"
          onClick={() => {
            setOpenConfirmUpdate(true);
          }}
          size="small"
          style={{ marginRight: 30 }}
        >
          Save & Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          size="small"
          color="error"
        >
          Cancel
        </Button>
      </div>
      <AlertDialog
        open={openConfirmUpdate}
        handleClose={() => {
          setOpenConfirmUpdate(false);
        }}
        handleConfirm={async () => {
          await updateCustomerInvoiceSettings(customerInvoiceSettings);
          setOpenConfirmUpdate(false);
        }}
        dialogTitle={`Confirm Update Customer Invoice Setttings?`}
      />
    </div>
  );
}
