import { useContext } from "react";
import { VishcorpSettingsContext } from "../context";
import AttributesComponent from "../UI/AttributesComponent";

export default function Customers() {
  const { organizationSettings } = useContext(VishcorpSettingsContext);
  const customerDefaultSettings =
    organizationSettings?.organizationSettings?.customerSettings;

  return (
    <AttributesComponent settings={customerDefaultSettings} label="Customer" />
  );
}
