import { Button, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { addSurchargeSet } from "./functions";
import { VishcorpContext } from "Context";

export default function AddSurchargeSet(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const { handleClose, refresh } = props;
  const [surchargeSetName, setSurchargeSetName] = useState(undefined);

  return (
    <div sx={{ padding: 2 }}>
      <Typography variant="h5">Add New Surcharge Set</Typography>
      <br />
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={surchargeSetName ?? ""}
        onChange={(e, value) => {
          setSurchargeSetName(e?.target?.value);
        }}
        label="Surcharge Set Name"
      />
      <br />
      <br />
      <div>
        <Button
          onClick={async () => {
            if (surchargeSetName === undefined) {
              return;
            }
            try {
              await addSurchargeSet(
                surchargeSetName,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              await refresh();
              handleClose();
            } catch (error) {
              console.error(error);
            }
          }}
          style={{ marginRight: 10 }}
          autoFocus
          variant="contained"
        >
          Confirm
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancel
        </Button>
      </div>
    </div>
  );
}
