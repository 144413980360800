export const newServiceOption = async (
  organizationID,
  serviceOption,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "NEW_SERVICE_OPTION",
          organizationID,
          serviceOption,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      .catch(console.error);
  } catch (error) {}
};

export const newPackageType = async (
  organizationID,
  packageType,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "NEW_PACKAGE_TYPE",
          organizationID,
          packageType,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      .catch(console.error);
  } catch (error) {}
};

export const newVehicleType = async (
  organizationID,
  vehicleType,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "NEW_VEHICLE_TYPE",
          organizationID,
          vehicleType,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      .catch(console.error);
  } catch (error) {}
};

export const deleteServiceOption = async (
  organizationID,
  serviceOptionName,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "DELETE_SERVICE_OPTION",
          organizationID: organizationID,
          serviceOptionName,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      //   .then((res) => res?.response)
      .catch(console.error);
  } catch (error) {}
};

export const deletePackageType = async (
  organizationID,
  packageTypeName,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "DELETE_PACKAGE_TYPE",
          organizationID: organizationID,
          packageTypeName,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      //   .then((res) => res?.response)
      .catch(console.error);
  } catch (error) {}
};

export const deleteVehicleType = async (
  organizationID,
  vehicleTypeName,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "DELETE_VEHICLE_TYPE",
          organizationID: organizationID,
          vehicleTypeName,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      //   .then((res) => res?.response)
      .catch(console.error);
  } catch (error) {}
};

export const getServiceOptions = async (
  organizationID,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "GET_SERVICE_OPTIONS",
          organizationID: organizationID,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => res?.response)
      .catch(console.error);
  } catch (error) {}
};

export const getPackageTypes = async (
  organizationID,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "GET_PACKAGE_TYPES",
          organizationID: organizationID,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => res?.response)
      .catch(console.error);
  } catch (error) {}
};

export const getVehicleType = async (
  organizationID,
  organizationTablePrefix
) => {
  try {
    return await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "GET_VEHICLE_TYPES",
          organizationID: organizationID,
          organizationTablePrefix,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => res?.response)
      .catch(console.error);
  } catch (error) {}
};
