import { Grid, Paper } from "@mui/material";
import EditOrder from "Components/Orders/EditOrder";
import { VishcorpContext } from "Context";
import { useEffect, useState } from "react";
import { useContext } from "react";
import OrderInfoHelper from "./OrderInfoHelper";
import { updateOrder } from "API/Orders";
// import OrderDetails from "../Orders/OrderDetails";

export default function OrderInfo(props) {
  const { selectedOrder, drivers, refreshOrder, orgSettings } =
    useContext(VishcorpContext);

  const [modifiedStatus, setModifiedStatus] = useState(undefined);
  const [selectedDriver, setSelectedDriver] = useState(undefined);

  useEffect(() => {
    setModifiedStatus(selectedOrder?.orderStatus);
    setSelectedDriver(
      drivers?.find((e) => {
        return e?.driverNumber === parseInt(selectedOrder?.assignedDriver);
      })?.driverName
    );
  }, [drivers, selectedOrder]);

  const onDispatch = async () => {
    const pDriverNumber = drivers?.find((e) => {
      return e?.driverName === selectedDriver;
    })?.driverNumber;
    if (
      modifiedStatus === selectedOrder?.orderStatus &&
      String(pDriverNumber) === String(selectedOrder?.assignedDriver)
    ) {
      return;
    }

    updateOrder(
      selectedOrder,
      modifiedStatus,
      String(pDriverNumber),
      orgSettings?.organizationTablePrefix
    ).then((res) => {
      refreshOrder();
    });
  };
  return (
    selectedOrder && (
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EditOrder
              onDispatch={onDispatch}
              selectedOrder={selectedOrder}
              // onDriverAssigned={(e) => setAssignedDriver(e)}
              onStatusChange={(e) => setModifiedStatus(e)}
              // activeOrder
            />
            {/* <OrderDetails
            selectedOrder={{
              orderer: selectedOrder?.orderer,
              orderTimestamp: selectedOrder?.orderTimestamp,
              postCode: selectedOrder?.postCode,
              pickupPostCode: selectedOrder?.postCode,
              pickupDetails: selectedOrder?.pickupDetails,
              deliveryDetails: selectedOrder?.deliveryDetails,
              parcelDetails: selectedOrder?.parcelDetails,
            }}
          /> */}
          </Grid>
        </Grid>
        <br />
        <Paper sx={{ paddingBottom: 2 }}>
          <OrderInfoHelper
            selectedDriver={selectedDriver}
            onDispatch={onDispatch}
            setSelectedDriver={setSelectedDriver}
          />
        </Paper>
      </Paper>
    )
  );
}
