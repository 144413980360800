import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { getDrivers } from "API/Drivers";
import { getAllDriverCommissions, getDriverCommissions } from "API/Invoices";
import { generatePDF } from "API/PDF";
import { VishcorpContext } from "Context";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

export default function DriverCommissions(props) {
  const { orgSettings } = useContext(VishcorpContext);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedDriver, setSelectedDriver] = useState(undefined);

  const [drivers, setDrivers] = useState(undefined);

  const [invoiceType, setInvoiceType] = useState(undefined);

  useEffect(() => {
    if (orgSettings?.organizationTablePrefix !== undefined) {
      getDrivers(orgSettings?.organizationTablePrefix).then((res) => {
        setDrivers(res?.drivers?.Items);
      });
    }
  }, [orgSettings]);

  return (
    <div style={{ padding: 10, marginLeft: 10 }}>
      <Typography variant="h5">Driver Commission Reports</Typography>
      <br />
      <div style={{ display: "flex" }}>
        <div>
          Start Date:
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div>
          End Date:
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
      </div>
      <br />
      <FormControl>
        <RadioGroup
          value={invoiceType}
          onChange={(e) => {
            setInvoiceType(e?.target?.value);
          }}
        >
          <FormControlLabel
            value="allDrivers"
            control={<Radio />}
            label="Print Reports for All Drivers"
          />

          <FormControlLabel
            value="selectedDriver"
            control={<Radio />}
            label="Print Reports for a specific Driver"
          />
        </RadioGroup>
      </FormControl>
      <br />

      {drivers?.length > 0 ? (
        <FormControl fullWidth size="small">
          <InputLabel>Select Driver</InputLabel>
          <Select
            style={{ width: "25%" }}
            size="small"
            value={selectedDriver?.driverID ?? ""}
            onChange={(e) =>
              setSelectedDriver(
                drivers?.find((f) => f?.driverID === e?.target?.value)
              )
            }
            label="Select Driver"
          >
            {drivers?.map((e) => (
              <MenuItem value={e?.driverID} key={e?.driverID}>
                {e?.driverName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
      <br />
      <br />
      <Button
        variant="contained"
        onClick={async () => {
          if (invoiceType === "allDrivers") {
            const driverCommissions = await getAllDriverCommissions(
              startDate,
              endDate,
              orgSettings?.organizationID,
              orgSettings?.organizationTablePrefix
            );
            generatePDF(driverCommissions?.response?.data);
          } else if (
            invoiceType === "selectedDriver" &&
            selectedDriver !== undefined
          ) {
            const driverCommissions = await getDriverCommissions(
              startDate,
              endDate,
              selectedDriver?.driverID,
              orgSettings?.organizationID,
              orgSettings?.organizationTablePrefix
            );
            generatePDF(driverCommissions?.response?.data);
          }
        }}
      >
        Print Reports
      </Button>
    </div>
  );
}
