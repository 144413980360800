import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useState } from "react";
import AddPostalCode from "./AddPostalCode";

export default function IncludedPostalCodes(props) {
  const { onAddPostalCode, onRemovePostalCode, postalCodes } = props;
  const [openAddPostalCode, setOpenAddPostalCode] = useState(false);
  const [selectedPostalCode, setSelectedPostalCode] = useState(false);
  return (
    <div>
      <Typography>Included Postal Codes</Typography>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenAddPostalCode(true)}
        >
          Add Postal Code
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (!selectedPostalCode) {
              return;
            }
            onRemovePostalCode(selectedPostalCode);
          }}
        >
          Remove
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Postal Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {postalCodes?.map((e) => (
              <TableRow
                hover
                selected={selectedPostalCode === e?.postalCode}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedPostalCode(e?.postalCode);
                }}
              >
                <TableCell>{e?.postalCode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        customControls={false}
        open={openAddPostalCode}
        handleClose={() => setOpenAddPostalCode(false)}
        dialogContent={
          <AddPostalCode
            onCancel={() => setOpenAddPostalCode(false)}
            onSave={(postalCode) => {
              onAddPostalCode(postalCode);
            }}
            onRefresh={async () => {
              // await refreshZoneSets();
            }}
          />
        }
      />
    </div>
  );
}
