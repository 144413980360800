import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import AddressInput from "Templates/UI/Input/AddressInput";

export default function AddCity(props) {
  const { onSave, onCancel } = props;
  const [cityDetails, setCityDetails] = useState(undefined);
  return (
    <div>
      <Typography>Add City</Typography>
      <AddressInput
        //   defaultValue={defaultValue}
        //  id={id}
        //   errors={errors}
        value={cityDetails?.address}
        setValue={(e) => {
          setCityDetails(e);
        }}
        label={"City Name"}
        //   required={required}
      />
      <TextField
        size="small"
        label="State"
        fullWidth
        value={cityDetails?.state}
        onChange={(e) =>
          setCityDetails((pre) => ({ ...(pre ?? {}), state: e?.target?.value }))
        }
      />
      <TextField
        style={{ marginTop: 10 }}
        fullWidth
        size="small"
        label="Country"
        value={cityDetails?.country}
        onChange={(e) =>
          setCityDetails((pre) => ({
            ...(pre ?? {}),
            country: e?.target?.value,
          }))
        }
      />
      <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
        <Button
          variant="contained"
          size="small"
          style={{ marginRight: 10 }}
          onClick={() => {
            onSave(cityDetails?.address, cityDetails);
            onCancel();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            onCancel();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
