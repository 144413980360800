import { Close } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { VishcorpPricingContext } from "./context";

export default function FlatRate(props) {
  const { pricingLists } = useContext(VishcorpPricingContext);
  const {
    serviceOptions,
    pricingList,
    onSavePriceList,
    setSelectedPricingList,
  } = props;
  const flatPricingList = pricingList?.pricingListDetails?.flatPrices;
  const [editFlatPricing, setEditFlatPricing] = useState(undefined);

  return (
    <div style={{ marginLeft: 20 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h5">Flat Rates</Typography>
      </div>
      <br />
      <div>
        <FormControl
          fullWidth
          style={{ width: "100%", marginRight: 10 }}
          size="small"
        >
          <InputLabel>Rollover Pricelist:</InputLabel>
          <Select
            fullWidth
            value={pricingList?.pricingListDetails?.backUpPricingList ?? ""}
            onChange={(e) => {
              setSelectedPricingList((pre) => ({
                ...(pre ?? {}),
                pricingListDetails: {
                  ...(pre?.pricingListDetails ?? {}),
                  backUpPricingList: e?.target?.value,
                },
              }));
            }}
            size="small"
            label="Rollover Pricelist:"
          >
            {pricingLists?.length > 0 &&
              pricingLists
                ?.filter(
                  (e) => e?.pricingListName !== pricingList?.pricingListName
                )
                ?.map((e, i) => (
                  <MenuItem key={i} value={e?.pricingListName}>
                    {e?.pricingListName}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Service Type</b>
              </TableCell>
              <TableCell>
                <b>Price</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {serviceOptions?.length > 0 &&
              serviceOptions?.map((e) => (
                <TableRow>
                  <TableCell sx={{ width: "50%" }}>{e?.name}</TableCell>
                  {editFlatPricing?.name === e?.name ? (
                    <TableCell sx={{ width: "50%" }}>
                      <TextField
                        size="small"
                        value={editFlatPricing?.value ?? ""}
                        onChange={(g) => {
                          const regex = /^(\d)*(\.)?([0-9]{1})?$/;
                          if (
                            g.target.value === "" ||
                            regex.test(g.target.value)
                          ) {
                            setEditFlatPricing((pre) => ({
                              ...pre,
                              value: g?.target?.value,
                            }));
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setSelectedPricingList((pre) => ({
                                    ...pre,
                                    pricingListDetails: {
                                      ...pre?.pricingListDetails,
                                      flatPrices: {
                                        ...(pre?.pricingListDetails
                                          ?.flatPrices ?? {}),
                                        [editFlatPricing?.name]:
                                          editFlatPricing?.value,
                                      },
                                    },
                                  }));
                                  setEditFlatPricing(undefined);
                                }}
                              >
                                <DoneIcon style={{ color: "blue" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setEditFlatPricing(undefined);
                                }}
                              >
                                <Close style={{ color: "red" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{ width: "50%" }}
                      onDoubleClick={() =>
                        setEditFlatPricing({
                          name: e?.name,
                          value: flatPricingList?.[e?.name],
                        })
                      }
                    >
                      {flatPricingList?.[e?.name]
                        ? `$ ${flatPricingList?.[e?.name]}`
                        : ""}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => {
            onSavePriceList();
          }}
        >
          Save
        </Button>
        <Button variant="contained">Cancel</Button>
      </div>
    </div>
  );
}
