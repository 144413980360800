import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function AddDistanceRange(props) {
  const { onSave, onClose } = props;
  const [distanceRange, setDistanceRange] = useState({
    from: "",
    to: "",
    metrics: "kms",
  });
  return (
    <div>
      <Typography variant="h5">Add Distance Range</Typography>
      <br />
      <TextField
        label="From"
        fullWidth
        value={distanceRange?.from ?? ""}
        onChange={(e) => {
          const regex = /^(\d)*(\.)?([0-9]{1})?$/;
          if (e.target.value === "" || regex.test(e.target.value)) {
            setDistanceRange((pre) => {
              return {
                ...(pre ?? {}),
                from: e?.target?.value,
              };
            });
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment>Kms</InputAdornment>,
        }}
      />
      <TextField
        label="To"
        value={distanceRange?.to ?? ""}
        fullWidth
        style={{ marginTop: 5 }}
        onChange={(e) => {
          const regex = /^(\d)*(\.)?([0-9]{1})?$/;
          if (e.target.value === "" || regex.test(e.target.value)) {
            setDistanceRange((pre) => {
              return {
                ...(pre ?? {}),
                to: e?.target?.value,
              };
            });
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment>Kms</InputAdornment>,
        }}
      />
      <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
        <Button
          variant="contained"
          onClick={async () => {
            await onSave(distanceRange);
          }}
          style={{ marginRight: 10 }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
