import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useState, useContext, useMemo } from "react";
import AddFuelCharge from "./AddFuelCharge";
import AddGeneralCharge from "./AddGeneralCharge";
import AddPieceCharge from "./AddPieceCharge";
import AddWeightCharge from "./AddWeightCharge";
import { VishcorpSurchargeContext } from "./context";
import AddMiscCharge from "./AddMiscCharge";
import { updateSurchargeSet } from "./functions";
import { VishcorpContext } from "Context";

export default function SurchargeDetails(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const { selectedSurchargeSet, setSelectedSurchargeSet, surchargeSetDetails } =
    useContext(VishcorpSurchargeContext);
  const [openAddCharge, setOpenAddCharge] = useState(false);
  const [dialogContent, setDialogContent] = useState(undefined);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const [selectedCharge, setSelectedCharge] = useState(undefined);

  const weightTable = useMemo(() => {
    if (surchargeSetDetails === undefined) {
      return;
    }
    let data = [];
    Object.keys(surchargeSetDetails?.weightCharges ?? {})?.forEach((k1) =>
      Object.keys(surchargeSetDetails?.weightCharges?.[k1] ?? {})?.forEach(
        (k2) =>
          Object.keys(
            surchargeSetDetails?.weightCharges?.[k1]?.[k2] ?? {}
          ).forEach((k3) => {
            data = [
              ...data,
              {
                serviceOption: k1,
                packageType: k2,
                vehicleType: k3,
                value: surchargeSetDetails?.weightCharges?.[k1]?.[k2]?.[k3],
              },
            ];
          })
      )
    );
    return data;
  }, [surchargeSetDetails]);

  const pieceTable = useMemo(() => {
    if (surchargeSetDetails === undefined) {
      return;
    }
    let data = [];
    Object.keys(surchargeSetDetails?.pieceCharges ?? {})?.forEach((k1) =>
      Object.keys(surchargeSetDetails?.pieceCharges?.[k1] ?? {})?.forEach(
        (k2) =>
          Object.keys(
            surchargeSetDetails?.pieceCharges?.[k1]?.[k2] ?? {}
          ).forEach((k3) => {
            data = [
              ...data,
              {
                serviceOption: k1,
                packageType: k2,
                vehicleType: k3,
                value: surchargeSetDetails?.pieceCharges?.[k1]?.[k2]?.[k3],
              },
            ];
          })
      )
    );
    return data;
  }, [surchargeSetDetails]);

  const fuelTable = useMemo(() => {
    if (surchargeSetDetails === undefined) {
      return;
    }
    let data = [];
    Object.keys(surchargeSetDetails?.fuelCharges ?? {})?.forEach((k1) =>
      Object.keys(surchargeSetDetails?.fuelCharges?.[k1] ?? {})?.forEach((k2) =>
        Object.keys(surchargeSetDetails?.fuelCharges?.[k1]?.[k2] ?? {}).forEach(
          (k3) => {
            data = [
              ...data,
              {
                serviceOption: k1,
                packageType: k2,
                vehicleType: k3,
                value:
                  surchargeSetDetails?.fuelCharges?.[k1]?.[k2]?.[k3]?.value,
                chargeType:
                  surchargeSetDetails?.fuelCharges?.[k1]?.[k2]?.[k3]
                    ?.chargeType,
              },
            ];
          }
        )
      )
    );
    return data;
  }, [surchargeSetDetails]);

  const generalTable = useMemo(() => {
    if (surchargeSetDetails === undefined) {
      return;
    }
    let data = [];
    Object.keys(surchargeSetDetails?.generalCharges ?? {})?.forEach((k1) =>
      Object.keys(surchargeSetDetails?.generalCharges?.[k1] ?? {})?.forEach(
        (k2) =>
          Object.keys(
            surchargeSetDetails?.generalCharges?.[k1]?.[k2] ?? {}
          ).forEach((k3) => {
            data = [
              ...data,
              {
                serviceOption: k1,
                packageType: k2,
                vehicleType: k3,
                value: surchargeSetDetails?.generalCharges?.[k1]?.[k2]?.[k3],
              },
            ];
          })
      )
    );
    return data;
  }, [surchargeSetDetails]);

  // const refresh = () => {};

  const onAddCharge = (chargeType) => {
    switch (chargeType) {
      case "weight":
        setDialogContent(
          <AddWeightCharge
            onSaveWeightCharges={function (weightCharges) {
              setSelectedSurchargeSet((pre) => {
                return {
                  ...(pre ?? {}),
                  surchargeSetDetails: {
                    ...(pre?.surchargeSetDetails ?? {}),
                    weightCharges: weightCharges,
                  },
                };
              });
            }}
            handleClose={() => setOpenAddCharge(false)}
          />
        );
        break;
      case "piece":
        setDialogContent(
          <AddPieceCharge
            onSavePieceCharges={function (pieceCharges) {
              setSelectedSurchargeSet((pre) => {
                return {
                  ...(pre ?? {}),
                  surchargeSetDetails: {
                    ...(pre?.surchargeSetDetails ?? {}),
                    pieceCharges: pieceCharges,
                  },
                };
              });
            }}
            handleClose={() => setOpenAddCharge(false)}
          />
        );
        break;
      case "fuel":
        setDialogContent(
          <AddFuelCharge
            onSaveFuelCharges={(fuelCharges) => {
              setSelectedSurchargeSet((pre) => {
                return {
                  ...(pre ?? {}),
                  surchargeSetDetails: {
                    ...(pre?.surchargeSetDetails ?? {}),
                    fuelCharges: fuelCharges,
                  },
                };
              });
            }}
            handleClose={() => setOpenAddCharge(false)}
          />
        );
        break;
      case "general":
        setDialogContent(
          <AddGeneralCharge
            onSaveGeneralCharges={(generalCharges) => {
              setSelectedSurchargeSet((pre) => {
                return {
                  ...(pre ?? {}),
                  surchargeSetDetails: {
                    ...(pre?.surchargeSetDetails ?? {}),
                    generalCharges: generalCharges,
                  },
                };
              });
            }}
            handleClose={() => setOpenAddCharge(false)}
          />
        );
        break;
      case "misc":
        setDialogContent(
          <AddMiscCharge
            onSaveMiscCharges={(miscCharge) => {
              setSelectedSurchargeSet((pre) => {
                return {
                  ...(pre ?? {}),
                  surchargeSetDetails: {
                    ...(pre?.surchargeSetDetails ?? {}),
                    miscCharges: [miscCharge, ...(pre?.miscCharges ?? [])],
                  },
                };
              });
            }}
            handleClose={() => setOpenAddCharge(false)}
          />
        );
        break;
      default:
        break;
    }
    setOpenAddCharge(true);
  };

  const onRemoveCharge = (chargeData, type) => {
    let pSelectedSurchargeSet = JSON.parse(
      JSON.stringify(selectedSurchargeSet)
    );
    switch (type) {
      case "miscCharges":
        pSelectedSurchargeSet.surchargeSetDetails.miscCharges =
          pSelectedSurchargeSet?.surchargeSetDetails?.miscCharges?.filter(
            (e) => chargeData?.chargeName !== e?.chargeName
          );
        break;
      case "fuelCharges":
        delete pSelectedSurchargeSet.surchargeSetDetails.fuelCharges[
          chargeData?.serviceOption
        ][chargeData?.packageType][chargeData?.vehicleType];
        break;
      case "generalCharges":
        delete pSelectedSurchargeSet.surchargeSetDetails.generalCharges[
          chargeData?.serviceOption
        ][chargeData?.packageType][chargeData?.vehicleType];
        break;
      case "weightCharges":
        delete pSelectedSurchargeSet.surchargeSetDetails.weightCharges[
          chargeData?.serviceOption
        ][chargeData?.packageType][chargeData?.vehicleType];
        break;
      case "pieceCharges":
        delete pSelectedSurchargeSet.surchargeSetDetails.pieceCharges[
          chargeData?.serviceOption
        ][chargeData?.packageType][chargeData?.vehicleType];
        break;
      default:
        break;
    }
    setSelectedSurchargeSet(pSelectedSurchargeSet);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">
              Surcharge Set: {selectedSurchargeSet?.surchargeSetName ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{ marginRight: 4 }}
                onClick={async () => {
                  await updateSurchargeSet(
                    selectedSurchargeSet?.surchargeSetName,
                    surchargeSetDetails,
                    orgSettings?.organizationID,
                    orgSettings?.organizationTablePrefix
                  );
                  setOpenAlertMessage(true);
                }}
              >
                Save
              </Button>
              <Button variant="contained" size="small">
                Close
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Weight Charges</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>
                  <b>Service</b>
                </TableCell>
                <TableCell>
                  <b>Package</b>
                </TableCell>
                <TableCell>
                  <b>Vehicle</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {weightTable?.length > 0 ? (
                weightTable?.map((e, i) => (
                  <TableRow
                    key={i}
                    hover
                    onClick={() => {
                      setSelectedCharge({
                        index: i,
                        chargeType: "weightCharges",
                      });
                    }}
                    selected={
                      selectedCharge?.index === i &&
                      selectedCharge?.chargeType === "weightCharges"
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{e?.serviceOption}</TableCell>
                    <TableCell>{e?.packageType}</TableCell>
                    <TableCell>{e?.vehicleType}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <Button
            variant="contained"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => {
              onAddCharge("weight");
            }}
          >
            Add Charge
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedCharge?.chargeType !== "weightCharges"}
            onClick={() => {
              onRemoveCharge(
                weightTable?.[selectedCharge?.index],
                "weightCharges"
              );
            }}
          >
            Remove
          </Button>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Piece Charges</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>
                  <b>Service</b>
                </TableCell>
                <TableCell>
                  <b>Package</b>
                </TableCell>
                <TableCell>
                  <b>Vehicle</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pieceTable?.length > 0 ? (
                pieceTable?.map((e, i) => (
                  <TableRow
                    key={i}
                    hover
                    onClick={() => {
                      setSelectedCharge({
                        index: i,
                        chargeType: "pieceCharges",
                      });
                    }}
                    selected={
                      selectedCharge?.index === i &&
                      selectedCharge?.chargeType === "pieceCharges"
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{e?.serviceOption}</TableCell>
                    <TableCell>{e?.packageType}</TableCell>
                    <TableCell>{e?.vehicleType}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <Button
            variant="contained"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => {
              onAddCharge("piece");
            }}
          >
            Add Charge
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedCharge?.chargeType !== "pieceCharges"}
            onClick={() => {
              onRemoveCharge(
                pieceTable?.[selectedCharge?.index],
                "pieceCharges"
              );
            }}
          >
            Remove
          </Button>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Fuel Surcharges</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>
                  <b>Service</b>
                </TableCell>
                <TableCell>
                  <b>Package</b>
                </TableCell>
                <TableCell>
                  <b>Vehicle</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fuelTable?.length > 0 ? (
                fuelTable?.map((e, i) => (
                  <TableRow
                    key={i}
                    hover
                    onClick={() => {
                      setSelectedCharge({
                        index: i,
                        chargeType: "fuelCharges",
                      });
                    }}
                    selected={
                      selectedCharge?.index === i &&
                      selectedCharge?.chargeType === "fuelCharges"
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{e?.serviceOption}</TableCell>
                    <TableCell>{e?.packageType}</TableCell>
                    <TableCell>{e?.vehicleType}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <Button
            variant="contained"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => {
              onAddCharge("fuel");
            }}
          >
            Add Charge
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedCharge?.chargeType !== "fuelCharges"}
            onClick={() => {
              onRemoveCharge(fuelTable?.[selectedCharge?.index], "fuelCharges");
            }}
          >
            Remove
          </Button>
        </div>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6">General Charges</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Service</b>
                </TableCell>
                <TableCell>
                  <b>Package</b>
                </TableCell>
                <TableCell>
                  <b>Vehicle</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generalTable?.length > 0 ? (
                generalTable?.map((e, i) => (
                  <TableRow
                    key={i}
                    hover
                    selected={
                      selectedCharge?.index === i &&
                      selectedCharge?.chargeType === "generalCharges"
                    }
                    onClick={() => {
                      setSelectedCharge({
                        index: i,
                        chargeType: "generalCharges",
                      });
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{e?.value?.surchargeName}</TableCell>
                    <TableCell>{e?.serviceOption}</TableCell>
                    <TableCell>{e?.packageType}</TableCell>
                    <TableCell>{e?.vehicleType}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <Button
            variant="contained"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => {
              onAddCharge("general");
            }}
          >
            Add Charge
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedCharge?.chargeType !== "generalCharges"}
            onClick={() => {
              onRemoveCharge(
                generalTable?.[selectedCharge?.index],
                "generalCharges"
              );
            }}
          >
            Remove
          </Button>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Misc. Charges</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Charge Name</b>
                </TableCell>
                <TableCell>
                  <b>Amount</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surchargeSetDetails?.miscCharges?.length > 0 ? (
                surchargeSetDetails?.miscCharges?.map((e, i) => (
                  <TableRow
                    key={i}
                    hover
                    selected={
                      selectedCharge?.index === i &&
                      selectedCharge?.chargeType === "miscCharges"
                    }
                    onClick={() => {
                      setSelectedCharge({
                        index: i,
                        chargeType: "miscCharges",
                      });
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{e?.chargeName}</TableCell>
                    <TableCell>{e?.chargeAmount}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <Button
            variant="contained"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => {
              onAddCharge("misc");
            }}
          >
            Add Charge
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedCharge?.chargeType !== "miscCharges"}
            onClick={() => {
              onRemoveCharge(
                surchargeSetDetails?.miscCharges?.[selectedCharge?.index],
                "miscCharges"
              );
            }}
          >
            Remove
          </Button>
        </div>
      </Grid>
      <AlertDialog
        customControls={false}
        handleClose={() => setOpenAddCharge(false)}
        open={openAddCharge}
        dialogContent={dialogContent}
      />
      <Snackbar
        open={openAlertMessage}
        autoHideDuration={6000}
        onClose={() => {
          setOpenAlertMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenAlertMessage(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Settings saved.
        </Alert>
      </Snackbar>
    </Grid>
  );
}
