import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function AddCompany(props) {
  const { onSave, onCancel } = props;
  const [companyName, setCompanyName] = useState(undefined);
  return (
    <div>
      <Typography>Add Company</Typography>
      <TextField
        size="small"
        label="Company Name"
        fullWidth
        value={companyName}
        onChange={(e) => setCompanyName(e?.target?.value)}
      />
      <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
        <Button
          variant="contained"
          size="small"
          style={{ marginRight: 10 }}
          onClick={() => {
            onSave(companyName);
            onCancel();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            onCancel();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
