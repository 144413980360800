import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { useState } from "react";

export default function AddPriceList(props) {
  const { onAddPriceList, onCancel, zoneSetList } = props;

  const [pricingListDetails, setPricingListDetails] = useState({
    pricingListName: "",
    pricingListType: "zoneBased",
    selectedZoneSet: "",
    singleZone: true,
  });

  return (
    <div>
      <Typography variant="h5">Add Pricing List</Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={pricingListDetails?.pricingListName}
            onChange={(e) => {
              setPricingListDetails((pre) => ({
                ...pre,
                pricingListName: e?.target?.value,
              }));
            }}
            label="Pricing List Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            {/* <FormLabel id="demo-radio-buttons-group-label">Zone</FormLabel> */}
            <RadioGroup
              value={pricingListDetails?.pricingListType}
              onChange={(e) => {
                setPricingListDetails((pre) => ({
                  ...pre,
                  pricingListType: e?.target?.value,
                }));
              }}
            >
              <FormControlLabel
                value="zoneBased"
                control={<Radio />}
                label="Zone Based"
              />
              <FormControlLabel
                value="flatBased"
                control={<Radio />}
                label="Flat Based"
              />
              <FormControlLabel
                value="distanceBased"
                control={<Radio />}
                label="Distance Based"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {pricingListDetails?.pricingListType === "zoneBased" && (
            <div>
              <FormControl
                fullWidth
                style={{ width: "100%", marginBottom: 15 }}
                size="small"
              >
                <InputLabel id="price-list-label">Zone Set</InputLabel>
                <Select
                  fullWidth
                  value={pricingListDetails?.selectedZoneSet}
                  size="small"
                  label="Zone Set"
                  onChange={(e) => {
                    console.log(e?.target?.value);
                    setPricingListDetails((pre) => ({
                      ...pre,
                      selectedZoneSet: e?.target?.value,
                    }));
                  }}
                  sx={{ marginBottom: 1 }}
                >
                  {zoneSetList?.length > 0 &&
                    zoneSetList?.map((e, i) => (
                      <MenuItem value={e?.zoneSetName} key={i}>
                        {e?.zoneSetName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pricingListDetails?.singleZone}
                    onChange={() => {
                      setPricingListDetails((pre) => ({
                        ...pre,
                        singleZone: !pre?.singleZone,
                      }));
                    }}
                  />
                }
                label="Single Zone"
              />
            </div>
          )}
          {pricingListDetails?.pricingListType === "flatBased" && <div></div>}
          {pricingListDetails?.pricingListType === "distanceBased" && (
            <div></div>
          )}
        </Grid>
      </Grid>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => {
            if (
              pricingListDetails?.pricingListName &&
              pricingListDetails?.pricingListType
            ) {
              onAddPriceList(pricingListDetails);
            }
          }}
        >
          Add
        </Button>
        <Button variant="contained" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
