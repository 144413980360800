import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { updateDispatcherAccess } from "API/Dispatchers";
import { VishcorpContext } from "Context";
import { useContext, useState } from "react";

export default function DispatcherDetails(props) {
  const { orgSettings, refreshOrganizationSettings } =
    useContext(VishcorpContext);
  const { handleClose } = props;
  const [dispatcherDetails, setDispatcherDetails] = useState(
    props?.dispatcherDetails
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Dispatcher Name</b>
              </TableCell>
              <TableCell>{dispatcherDetails?.dispatcherName ?? ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>{dispatcherDetails?.email ?? ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Phone</b>
              </TableCell>
              <TableCell>{dispatcherDetails?.phone ?? ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Address</b>
              </TableCell>
              <TableCell>{dispatcherDetails?.address?.address ?? ""}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Typography variant="h6">Access Control</Typography>
      <FormControlLabel
        label="Settings"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.settings ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    settings: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Completed Orders"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.completedOrders ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    completedOrders: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Drivers"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.drivers ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    drivers: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Customers"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.customers ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    customers: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Invoices"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.invoices ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    invoices: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />

      <br />
      <div>
        <Button
          variant="contained"
          size="small"
          onClick={async () => {
            await updateDispatcherAccess(
              dispatcherDetails?.dispatcherID,
              dispatcherDetails?.accessControl,
              orgSettings?.organizationID
            );
            await refreshOrganizationSettings(orgSettings?.organizationID);
            handleClose();
          }}
        >
          Save Changes
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: 10 }}
          size="small"
          onClick={() => {
            handleClose();
          }}
          color="error"
        >
          Close
        </Button>
      </div>
    </div>
  );
}
