import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { VishcorpSettingsContext } from "Components/CompanySettings/context";
import { useContext, useState } from "react";
import AddPieceValues from "./AddPieceValues";
import { VishcorpSurchargeContext } from "./context";

export default function AddPieceCharge(props) {
  const { surchargeSetDetails } = useContext(VishcorpSurchargeContext);
  const { onSavePieceCharges, handleClose } = props;
  const { organizationSettings } = useContext(VishcorpSettingsContext);
  const [selectedVehicleType, setSelectedVehicleType] = useState(undefined);
  const [selectedPackageType, setSelectedPackageType] = useState(undefined);
  const [selectedServiceOption, setSelectedServiceOption] = useState(undefined);
  const [openAddPieceValue, setOpenAddPieceValue] = useState(false);

  const { packageTypes, serviceOptions, vehicleTypes } = organizationSettings;

  const [pieceCharges, setPieceCharges] = useState(
    surchargeSetDetails?.pieceCharges ?? {}
  );

  function onAddPieceCharge(pieceCharge) {
    setPieceCharges((pre) => {
      return {
        ...(pre ?? {}),
        [selectedServiceOption]: {
          ...(pre?.[selectedServiceOption] ?? {}),
          [selectedPackageType]: {
            ...(pre?.[selectedServiceOption]?.[selectedPackageType] ?? {}),
            [selectedVehicleType]: [
              pieceCharge,
              ...(pre?.[selectedServiceOption]?.[selectedPackageType]?.[
                selectedVehicleType
              ] ?? []),
            ],
          },
        },
      };
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FormControl
            fullWidth
            style={{ width: "100%", marginRight: 10 }}
            size="small"
          >
            <InputLabel id="price-list-label">Apply to Service</InputLabel>
            <Select
              fullWidth
              value={selectedServiceOption ?? ""}
              size="small"
              label="Apply to Service"
              onChange={(e) => {
                setSelectedServiceOption(e?.target?.value);
              }}
            >
              {serviceOptions?.length > 0 &&
                [{ name: "All Services" }, ...serviceOptions]?.map((e, i) => (
                  <MenuItem key={i} value={e?.name}>
                    {e?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{ width: "100%", marginRight: 10 }}
            size="small"
          >
            <InputLabel id="price-list-label">Apply to Package type</InputLabel>
            <Select
              fullWidth
              value={selectedPackageType ?? ""}
              size="small"
              label="Apply to Package type"
              onChange={(e) => {
                setSelectedPackageType(e?.target?.value);
              }}
            >
              {packageTypes?.length > 0 &&
                [{ name: "All Package Types" }, ...packageTypes]?.map(
                  (e, i) => (
                    <MenuItem key={i} value={e?.name}>
                      {e?.name}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ width: "100%" }} size="small">
            <InputLabel id="price-list-label">Apply to Vehicle type</InputLabel>
            <Select
              fullWidth
              value={selectedVehicleType ?? ""}
              size="small"
              label="Apply to Vehicle type"
              onChange={(e) => {
                setSelectedVehicleType(e?.target?.value);
              }}
            >
              {vehicleTypes?.length > 0 &&
                [{ name: "All Vehicle Types" }, ...vehicleTypes]?.map(
                  (e, i) => (
                    <MenuItem key={i} value={e?.name}>
                      {e?.name}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                size="small"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setOpenAddPieceValue(true);
                }}
                disabled={
                  !(
                    selectedPackageType &&
                    selectedServiceOption &&
                    selectedVehicleType
                  )
                }
              >
                Add
              </Button>
              <Button variant="contained" size="small">
                Remove
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox onChange={() => {}} />}
              label="Apply change for entire order"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ height: 500, maxHeight: 500, overflow: "scroll" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>From (kg)</b>
                </TableCell>
                <TableCell>
                  <b>To (kg)</b>
                </TableCell>
                <TableCell>
                  <b>Price</b>
                </TableCell>
                <TableCell>
                  <b>Pieces</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedPackageType !== undefined &&
              selectedVehicleType !== undefined &&
              selectedServiceOption !== undefined &&
              pieceCharges !== undefined ? (
                (
                  pieceCharges?.[selectedServiceOption]?.[
                    selectedPackageType
                  ]?.[selectedVehicleType] || []
                ).map((e, i) => (
                  <TableRow key={i}>
                    <TableCell>{e?.from}</TableCell>
                    <TableCell>{e?.to}</TableCell>
                    <TableCell>{e?.price}</TableCell>
                    <TableCell>{e?.pieces}</TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onSavePieceCharges(pieceCharges);
              handleClose();
            }}
          >
            Save and Close
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClose()}
          >
            Exit
          </Button>
        </div>
      </Grid>
      <AlertDialog
        open={openAddPieceValue}
        customControls={false}
        handleClose={() => setOpenAddPieceValue(false)}
        dialogContent={
          <AddPieceValues
            addPiece={(pieceValues, config) => {
              if (onAddPieceCharge !== undefined) {
                onAddPieceCharge(pieceValues, config);
              }
            }}
            handleClose={() => setOpenAddPieceValue(false)}
            {...{
              selectedPackageType,
              selectedServiceOption,
              selectedVehicleType,
            }}
          />
        }
      />
    </Grid>
  );
}
