import { Button, TextField, Typography } from "@mui/material";
import { updateInvoiceSettings } from "API/Invoices";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { VishcorpContext } from "Context";
import { useContext, useState } from "react";
import CustomizeCustomerInvoice from "./CustomizeCustomerInvoice";
import CustomizeDriverInvoice from "./CustomizeDriverInvoice";

export default function InvoiceSettings(props) {
  const { orgSettings, refreshOrganizationSettings } =
    useContext(VishcorpContext);
  const [invoiceSettings, setInvoiceSettings] = useState(
    orgSettings?.invoiceSettings ?? {
      tax: 0,
      fuelSurcharge: 0,
      customerInvoiceSettings: {
        firstOption: "",
        secondOption: "",
        thirdOption: "",
        fourthOption: "",
        fifthOption: "",
        sixthOption: "",
        seventhOption: "",
        eighthOption: "",
      },
      driverInvoiceSettings: {
        firstOption: "",
        secondOption: "",
        thirdOption: "",
      },
    }
  );
  const [openCustomizeCustomerInvoice, setOpenCustomizeCustomerInvoice] =
    useState(false);
  const [openCustomizeDriverInvoice, setOpenCustomizeDriverInvoice] =
    useState(false);

  const [openUpdateInvoiceSettings, setOpenUpdateInvoiceSettings] =
    useState(false);
  return (
    <div style={{ padding: 10, marginLeft: 10 }}>
      <Typography variant="h5">Invoice Settings</Typography>
      <br />
      <TextField
        size="small"
        variant="outlined"
        label="Tax %"
        style={{ width: "50%" }}
        value={invoiceSettings?.tax}
        onChange={(e) => {
          const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
          if (e.target.value === "" || regex.test(e.target.value)) {
            if (
              parseFloat(e?.target?.value) >= 0 &&
              parseFloat(e?.target?.value) <= 100
            ) {
              setInvoiceSettings((pre) => ({
                ...pre,
                tax: e?.target?.value,
              }));
            }
          }
        }}
      />
      <br />
      <br />
      <TextField
        size="small"
        variant="outlined"
        label="Fuel Surcharge %"
        style={{ width: "50%" }}
        value={invoiceSettings?.fuelSurcharge}
        onChange={(e) => {
          const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
          if (e.target.value === "" || regex.test(e.target.value)) {
            if (
              parseFloat(e?.target?.value) >= 0 &&
              parseFloat(e?.target?.value) <= 100
            ) {
              setInvoiceSettings((pre) => ({
                ...pre,
                fuelSurcharge: e?.target?.value,
              }));
            }
          }
        }}
      />
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => setOpenCustomizeCustomerInvoice(true)}
      >
        Customize Customer Invoice
      </Button>
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => setOpenCustomizeDriverInvoice(true)}
      >
        Customize Driver Invoice
      </Button>
      <br />
      <br />
      <div>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpenUpdateInvoiceSettings(true);
          }}
          style={{ marginRight: 10 }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {}}
          color="error"
        >
          Cancel
        </Button>
      </div>
      <AlertDialog
        open={openUpdateInvoiceSettings}
        handleClose={() => {
          setOpenUpdateInvoiceSettings(false);
        }}
        handleConfirm={async () => {
          await updateInvoiceSettings(
            invoiceSettings,
            orgSettings?.organizationID,
            orgSettings?.organizationName
          );
          await refreshOrganizationSettings();
          setOpenUpdateInvoiceSettings(false);
        }}
        dialogTitle={`Confirm Update Invoice Setttings?`}
      />
      <AlertDialog
        open={openCustomizeCustomerInvoice}
        handleClose={() => setOpenCustomizeCustomerInvoice(false)}
        customControls={false}
        maxWidth="xl"
        dialogContent={
          <CustomizeCustomerInvoice
            customerInvoiceSettings={invoiceSettings?.customerInvoiceSettings}
            updateCustomerInvoiceSettings={async (customerInvoiceSettings) => {
              await updateInvoiceSettings(
                { ...invoiceSettings, customerInvoiceSettings },
                orgSettings?.organizationID,
                orgSettings?.organizationName
              );
              await refreshOrganizationSettings(orgSettings?.organizationID);
              setOpenCustomizeCustomerInvoice(false);
            }}
            handleClose={() => {
              setOpenCustomizeCustomerInvoice(false);
            }}
          />
        }
      />
      <AlertDialog
        open={openCustomizeDriverInvoice}
        handleClose={() => setOpenCustomizeDriverInvoice(false)}
        customControls={false}
        maxWidth="md"
        dialogContent={
          <CustomizeDriverInvoice
            driverInvoiceSettings={invoiceSettings?.driverInvoiceSettings}
            updateDriverInvoiceSettings={async (driverInvoiceSettings) => {
              await updateInvoiceSettings(
                { ...invoiceSettings, driverInvoiceSettings },
                orgSettings?.organizationID,
                orgSettings?.organizationName
              );
              await refreshOrganizationSettings(orgSettings?.organizationID);
              setOpenCustomizeDriverInvoice(false);
            }}
            handleClose={() => {
              setOpenCustomizeDriverInvoice(false);
            }}
          />
        }
      />
    </div>
  );
}
