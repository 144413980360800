import { Grid, Paper } from "@mui/material";
import Pricing from "Components/Pricing";
import React, { useContext, useEffect, useState } from "react";
import { VishcorpSettingsContext } from "./context";
import Customers from "./Customers";
import DeliverySettings from "./DeliverySettings";
import Drivers from "./Drivers";
import Options from "./Options";
import Orders from "./Orders";
import { VishcorpContext } from "Context";
import GeneralSettings from "./GeneralSettings";
import GlobalUsers from "Components/GlobalUsers";
import Dispatchers from "./Dispatchers";

export default function CompanySettings(props) {
  const [selectedSettings, setSelectedSettings] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState("Customers");
  const { orgSettings, refreshOrganizationSettings } =
    useContext(VishcorpContext);

  useEffect(() => {
    if (!selectedOption) {
      return;
    }
    switch (selectedOption) {
      case "Customers":
        setSelectedSettings(<Customers />);
        break;
      case "Orders":
        setSelectedSettings(<Orders />);
        break;
      case "Drivers":
        setSelectedSettings(<Drivers />);
        break;
      case "Delivery Settings":
        setSelectedSettings(<DeliverySettings />);
        break;
      case "General Settings":
        setSelectedSettings(<GeneralSettings />);
        break;
      case "Global Users":
        setSelectedSettings(<GlobalUsers />);
        break;
      case "Pricing":
        setSelectedSettings(<Pricing />);
        break;
      case "Dispatchers":
        setSelectedSettings(<Dispatchers />);
        break;
      default:
        break;
    }
  }, [selectedOption]);

  return (
    <VishcorpSettingsContext.Provider
      value={{
        organizationSettings: orgSettings,
        updateOrganizationSettings: () =>
          refreshOrganizationSettings(orgSettings?.organizationID),
      }}
    >
      <Grid container sx={{ height: "100%", flexGrow: 1 }}>
        <Grid item xs={2}>
          <Paper sx={{ height: "100vh" }}>
            <Options
              onOptionChanged={(option) => {
                setSelectedOption(option);
              }}
              selectedOption={selectedOption}
            />
          </Paper>
        </Grid>
        <Grid item xs={10}>
          {selectedSettings && selectedSettings}
        </Grid>
      </Grid>
    </VishcorpSettingsContext.Provider>
  );
}
