import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useState } from "react";
import AddCity from "./AddCity";

export default function IncludedCities(props) {
  const { onAddCity, onRemoveCity, cities } = props;
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [openAddCity, setOpenAddCity] = useState(undefined);
  return (
    <div>
      <Typography>Included Citites</Typography>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenAddCity(true)}
        >
          Add City
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (!selectedCity) {
              return;
            }
            onRemoveCity(selectedCity);
          }}
        >
          Remove
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Country</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cities &&
              cities?.map((e) => (
                <TableRow
                  hover
                  selected={selectedCity === e?.cityName}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedCity(e?.cityName);
                  }}
                >
                  <TableCell>{e?.cityName}</TableCell>
                  <TableCell>{e?.cityDetails?.state}</TableCell>
                  <TableCell>{e?.cityDetails?.country}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        customControls={false}
        open={openAddCity}
        handleClose={() => setOpenAddCity(false)}
        dialogContent={
          <AddCity
            onCancel={() => setOpenAddCity(false)}
            onSave={(cityName, cityDetails) => {
              onAddCity(cityName, cityDetails);
            }}
            onRefresh={async () => {
              // await refreshZoneSets();
            }}
          />
        }
      />
    </div>
  );
}
