import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function MapZones(props) {
  return (
    <div>
      <Typography>Map Zones</Typography>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" size="small">
          Add Map Zone
        </Button>
        <Button variant="contained" size="small">
          Remove
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Map Zone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* <TableCell>City</TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
