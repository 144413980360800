import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { addDispatcher } from "API/Dispatchers";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { VishcorpContext } from "Context";
import AddressInput from "Templates/UI/Input/AddressInput";
import Email from "Templates/UI/Input/Email";
import Phone from "Templates/UI/Input/Phone";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

export default function AddDispatcher(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const { handleClose, refresh } = props;
  const [confirmAddDispatcher, setConfirmAddDispatcher] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [dispatcherDetails, setDispatcherDetails] = useState({
    dispatcherName: "",
    phone: "",
    address: "",
    email: "",
  });
  return (
    <div>
      <br />
      <TextField
        fullWidth
        error={errors?.dispatcherName && true}
        helperText={errors?.dispatcherName?.message}
        {...register("dispatcherName", {
          required: "Required*",
        })}
        label="Name"
        value={dispatcherDetails?.dispatcherName}
        onChange={(e) =>
          setDispatcherDetails((pre) => {
            return { ...pre, dispatcherName: e?.target?.value };
          })
        }
        variant="outlined"
        size="small"
      />
      <Phone
        errors={errors}
        required={true}
        register={register}
        style={{ marginTop: 10 }}
        value={dispatcherDetails?.phone}
        setValue={(e) => {
          setDispatcherDetails((pre) => {
            return { ...pre, phone: e };
          });
        }}
        label={"Phone"}
      />
      <Email
        required={true}
        register={register}
        errors={errors}
        style={{ marginTop: 10 }}
        value={dispatcherDetails?.email}
        setValue={(e) => {
          setDispatcherDetails((pre) => {
            return { ...pre, email: e };
          });
        }}
        label={"Email"}
      />
      <AddressInput
        register={register}
        errors={errors}
        required={true}
        style={{ marginTop: 10 }}
        value={dispatcherDetails?.address}
        setValue={(e) => {
          setDispatcherDetails((pre) => {
            return { ...pre, address: e };
          });
        }}
        label={"Address"}
      />

      <Typography variant="h6">Access Control</Typography>
      <FormControlLabel
        label="Settings"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.settings ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    settings: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Completed Orders"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.completedOrders ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    completedOrders: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Drivers"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.drivers ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    drivers: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Customers"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.customers ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    customers: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <FormControlLabel
        label="Invoices"
        control={
          <Checkbox
            checked={dispatcherDetails?.accessControl?.invoices ?? false}
            onChange={(e) => {
              setDispatcherDetails((pre) => {
                return {
                  ...pre,
                  accessControl: {
                    ...(pre?.accessControl ?? {}),
                    invoices: e?.target?.checked,
                  },
                };
              });
            }}
          />
        }
      />
      <br />
      <Button
        sx={{
          borderRadius: 0,
        }}
        variant="contained"
        onClick={handleSubmit(() => {
          setConfirmAddDispatcher(true);
        })}
      >
        Add Dispatcher
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: 10 }}
        sx={{
          borderRadius: 0,
        }}
        color="error"
        onClick={() => {
          handleClose();
        }}
      >
        Cancel
      </Button>
      <AlertDialog
        open={confirmAddDispatcher}
        handleClose={() => {
          setConfirmAddDispatcher(false);
        }}
        handleConfirm={async () => {
          await addDispatcher(dispatcherDetails, orgSettings?.organizationID);
          refresh();
          setConfirmAddDispatcher(false);
        }}
        dialogTitle="Confirm Add Dispatcher"
      />
    </div>
  );
}
