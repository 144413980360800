export const updateGeneralSettings = async (
  generalSettings,
  organizationID,
  organizationName
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_GENERAL_SETTINGS",
        generalSettings: generalSettings,
        organizationID: organizationID,
        organizationName,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateOrganizationLogo = async (organizationID, logo) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_ORGANIZATION_LOGO",
        organizationID: organizationID,
        logo,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getOrganizationLogo = async (organizationID) => {
  if (!organizationID) return;
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORGANIZATION_LOGO",
        organizationID: organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res.response.Body)
    .catch(console.error);
};
