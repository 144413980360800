import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import IncludeOptions from "./IncludeOptions";
import { useForm } from "react-hook-form";

export default function AddZone(props) {
  const { onUpdateZone, onCancel, isEdit, zoneDetails } = props;
  const [zoneName, setZoneName] = useState(zoneDetails?.zoneName ?? "");
  const [includedOptions, setIncludedOptions] = useState(
    zoneDetails?.includedOptions ?? {
      cities: [],
      postalCodes: [],
      companies: [],
      drivers: [],
      mapZones: [],
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div style={{ height: "80vh", width: "80vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ marginTop: 6 }}>
          <TextField
            fullWidth
            // disabled={isEdit}
            label="Zone Name"
            {...register("zoneName", {
              required: "Required*",
            })}
            error={errors?.["zoneName"] && true}
            value={zoneName}
            onChange={(e) => {
              if (isEdit) {
                return;
              }
              setZoneName(e?.target?.value);
            }}
          />
        </Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={12} sx={{ height: "65vh" }}>
          <IncludeOptions
            includedOptions={includedOptions}
            setIncludedOptions={setIncludedOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              size="small"
              style={{ marginRight: 10 }}
              onClick={handleSubmit(() => {
                if (!zoneName) {
                  onCancel();
                  return;
                }
                onUpdateZone({ zoneName, includedOptions });
              })}
            >
              Save
            </Button>
            <Button variant="contained" size="small" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
