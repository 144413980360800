import RGL, { WidthProvider } from "react-grid-layout";
// import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import React, { useState } from "react";
// import Orders from "Components/Orders/Orders";
import "react-grid-layout/css/styles.css";
import InfoPanel from "Components/InfoPanel";
import CompletedOrderList from "./CompletedOrderList";

const ResponsiveGridLayout = WidthProvider(RGL);
export default function CompletedOrders(props) {
  const [hideSidebar, setHideSidebar] = useState(false);
  const [layout, setLayout] = useState([
    {
      i: "1-left",
      x: 0,
      y: 0,
      w: 8,
      h: 26,
      isDraggable: false,
      isResizable: true,
      resizeHandles: ["se", "e", "s"],
    },
    {
      i: "2-right",
      x: 8,
      y: 0,
      w: 4,
      h: 26,
      isDraggable: false,
      isResizable: true,
      // resizeHandles: ["w"],
    },
    // {
    //   i: "3-left",
    //   x: 0,
    //   y: 18,
    //   w: 8,
    //   h: 13,
    //   minH: 6,
    //   isDraggable: false,
    //   isResizable: true,
    //   resizeHandles: ["se", "e", "s"],
    // },
  ]);

  const handleLayoutChange = (pLayout, newVal) => {
    const changeIndex = newVal?.i;
    const changeValue = newVal?.w;
    const group = newVal?.i?.split("-")?.[1];

    let newLayout = pLayout?.map((e) => {
      if (e?.i !== changeIndex && group !== e?.i?.split("-")?.[1]) {
        switch (e?.i?.split("-")?.[1]) {
          case "right":
            return {
              ...e,
              w: 12 - changeValue,
              x: changeValue,
              isDraggable: false,
              isResizable: true,
            };
          default:
            return {
              ...e,
              w: 12 - changeValue,
              isDraggable: false,
              isResizable: true,
            };
        }
      } else if (e?.i !== changeIndex && group === e?.i?.split("-")?.[1]) {
        return { ...e, w: changeValue };
      }
      switch (e?.i?.split("-")?.[1]) {
        case "right":
          return {
            ...e,
            w: changeValue,
            x: 12 - changeValue,
            isDraggable: false,
            isResizable: true,
          };
        default:
          return { ...e, isDraggable: false, isResizable: true };
      }
    });

    let firstEle = newLayout?.find((e) => e?.i === "1-left");

    if (firstEle?.w >= 11) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
    setLayout(newLayout);
  };

  return (
    <div>
      <ResponsiveGridLayout
        // width={1600}
        layout={layout}
        measureBeforeMount={true}
        className="layout"
        rowHeight={30}
        items={3}
        onResize={function (resizeProps, preVal, newVal) {
          if (newVal?.minH <= newVal?.h || !newVal?.minH) {
            handleLayoutChange(resizeProps, newVal);
          }
        }}
        onLayoutChange={(pLayout) => {
          if (!hideSidebar) {
            const rightElement = pLayout?.find((e) => e?.i === "2-right");
            if (rightElement?.x === 0) {
              handleLayoutChange(pLayout, pLayout[0]);
            }
          }
        }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        // cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        cols={12}
      >
        <div key="1-left" className="grid-item" style={{ overflow: "scroll" }}>
          <CompletedOrderList />
        </div>
        {!hideSidebar && (
          <div key="2-right" className="grid-item">
            <InfoPanel />
            {/* <Drivers /> */}
          </div>
        )}
      </ResponsiveGridLayout>
     
    </div>
  );
}
