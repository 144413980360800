import { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { getCustomerInvoices } from "API/Invoices";
import { VishcorpContext } from "Context";
import { generatePDF } from "API/PDF";
import { getCustomers } from "API/Customers";
import { getInvoiceByCustomer } from "API/Invoices";

export default function CustomerInvoices(props) {
  const { orgSettings } = useContext(VishcorpContext);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedCustomerID, setSelectedCustomerID] = useState(undefined);

  const [customers, setCustomers] = useState(undefined);

  const [invoiceType, setInvoiceType] = useState(undefined);

  useEffect(() => {
    if (orgSettings?.organizationTablePrefix !== undefined) {
      getCustomers(orgSettings?.organizationTablePrefix).then((res) => {
        setCustomers(res?.customers?.Items);
      });
    }
  }, [orgSettings]);

  return (
    <div style={{ padding: 10, marginLeft: 10 }}>
      <Typography variant="h5">Customer Invoices</Typography>
      <br />
      <div style={{ display: "flex" }}>
        <div>
          Start Date:
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div>
          End Date:
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
      </div>
      <br />
      <FormControl>
        <RadioGroup
          value={invoiceType}
          onChange={(e) => {
            setInvoiceType(e?.target?.value);
          }}
        >
          <FormControlLabel
            value="allCustomers"
            control={<Radio />}
            label="Print Reports for All Customers"
          />
          <div>
            <FormControlLabel
              value="billingGroup"
              control={<Radio disabled />}
              label="Print Reports for a Billing Group"
            />
            <Select defaultValue="Everyday" disabled>
              <MenuItem value="Everyday" key="everyday">
                Everyday
              </MenuItem>
            </Select>
          </div>
          <FormControlLabel
            value="selectedCustomer"
            control={<Radio />}
            label="Print Reports for a specific Customer"
          />
        </RadioGroup>
      </FormControl>
      <br />

      {customers?.length > 0 ? (
        <FormControl fullWidth size="small">
          <InputLabel>Select Customer</InputLabel>
          <Select
            style={{ width: "25%" }}
            size="small"
            value={selectedCustomerID}
            onChange={(e) => setSelectedCustomerID(e?.target?.value)}
            label="Select Customer"
          >
            {customers?.map((e) => (
              <MenuItem value={e?.customerID} key={e?.customerID}>
                {e?.customerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
      <br />
      <br />
      <Button
        variant="contained"
        onClick={async () => {
          if (invoiceType === "allCustomers") {
            const customerInvoices = await getCustomerInvoices(
              startDate,
              endDate,
              orgSettings?.organizationID,
              orgSettings?.organizationTablePrefix
            );
            generatePDF(customerInvoices?.response?.data);
          }
          if (
            invoiceType === "selectedCustomer" &&
            selectedCustomerID !== undefined
          ) {
            const customerInvoices = await getInvoiceByCustomer(
              startDate,
              endDate,
              selectedCustomerID,
              orgSettings?.organizationID,
              orgSettings?.organizationTablePrefix
            );
            generatePDF(customerInvoices?.response?.data);
          }
        }}
      >
        Print Invoices
      </Button>
    </div>
  );
}
