import { Box, Button, Paper, Typography } from "@mui/material";
import { addAttribute, deleteAttribtue, updateAttribute } from "API";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { primaryButtonHoverColor } from "constants";
import { primaryButtonColor } from "constants";
import { useContext, useState } from "react";
import { VishcorpSettingsContext } from "../context";
import AttributeTable from "../UI/AttributeTable";
import NewAttribute from "./NewAttribute";
import { VishcorpContext } from "Context";

export default function AttributesComponent(props) {
  const { settings, label } = props;
  const [openNewAttributeDialog, setOpenNewAttribtueDialog] = useState(false);
  const { orgSettings } = useContext(VishcorpContext);
  const { updateOrganizationSettings } = useContext(VishcorpSettingsContext);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Paper sx={{ padding: 2, marginLeft: 1, flexGrow: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{`${label} Attributes`}</Typography>
          <Button
            sx={{
              borderRadius: 0,
              backgroundColor: primaryButtonColor,
              "&:hover": {
                backgroundColor: primaryButtonHoverColor,
              },
            }}
            variant="contained"
            onClick={() => {
              setOpenNewAttribtueDialog(true);
            }}
          >
            New Attribute
          </Button>
        </div>
        <br />
        <AttributeTable
          settings={settings}
          handleDeleteAttribute={async (deleteItem) => {
            await deleteAttribtue(
              label,
              deleteItem,
              orgSettings?.organizationID,
              orgSettings?.organizationTablePrefix
            );
            updateOrganizationSettings();
          }}
          handleSwitchVisibility={async (item) => {
            await updateAttribute(
              label,
              {
                ...item,
                data: { ...item?.data, visible: !item?.data?.visible },
              },
              orgSettings?.organizationID,
              orgSettings?.organizationTablePrefix
            );
            updateOrganizationSettings();
          }}
        />
      </Paper>
      <AlertDialog
        customControls={false}
        open={openNewAttributeDialog}
        handleClose={() => setOpenNewAttribtueDialog(false)}
        dialogTitle={`New ${label} Attribute`}
        dialogContent={
          <NewAttribute
            handleClose={() => setOpenNewAttribtueDialog(false)}
            isOrder={label === "Order"}
            handleUpdate={async (formData) => {
              await addAttribute(
                label,
                formData,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              updateOrganizationSettings();
            }}
          />
        }
      />
    </Box>
  );
}
