import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { getServiceOptions } from "Components/CompanySettings/DeliverySettings/functions";
import { useState, useEffect, useContext } from "react";
import {
  addPricingList,
  getPricingLists,
  getZoneSets,
  removePricingList,
  updatePricingList,
} from "../functions";
import AddPriceList from "./AddPriceList";
import { VishcorpPricingContext } from "./context";
import DistanceBased from "./DistanceBased";
import FlatRate from "./FlatRate";
import ZoneBased from "./ZoneBased";
import { VishcorpContext } from "Context";

export default function PriceLists(props) {
  const { orgSettings } = useContext(VishcorpContext);

  const [openAddPricingList, setOpenAddPricingList] = useState(false);
  const [openRemovePricingList, setOpenRemovePricingList] = useState(false);
  const [zoneSetList, setZoneSetList] = useState(undefined);
  const [pricingLists, setPricingLists] = useState(undefined);
  const [selectedPricingList, setSelectedPricingList] = useState(undefined);

  const [serviceOptions, setServiceOptions] = useState(undefined);

  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  const refreshPricingLists = async () => {
    await getPricingLists(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        if (selectedPricingList !== undefined) {
          setSelectedPricingList(
            res.find(
              (e) => e?.pricingListName === selectedPricingList?.pricingListName
            )
          );
        } else {
          setSelectedPricingList(undefined);
        }
        setPricingLists(res);
      })
      .catch(console.error);
  };

  useEffect(() => {
    (async () => {
      await getPricingLists(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setPricingLists(res);
        })
        .catch(console.error);
    })();
    getServiceOptions(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => setServiceOptions(res))
      .catch(console.error);
  }, [orgSettings]);

  useEffect(() => {
    (async () => {
      await getZoneSets(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setZoneSetList(res);
        })
        .catch(console.error);
    })();
  }, [orgSettings]);

  return (
    <VishcorpPricingContext.Provider value={{ pricingLists }}>
      <Grid container>
        <Grid item xs={4}>
          <TableContainer component={Paper} sx={{ height: "70vh" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pricelist</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingLists?.length > 0 &&
                  pricingLists?.map((e, i) => (
                    <TableRow
                      hover
                      selected={
                        selectedPricingList?.pricingListName ===
                        e?.pricingListName
                      }
                      onClick={() => setSelectedPricingList(e)}
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{e?.pricingListName}</TableCell>
                      <TableCell>
                        {e?.pricingListType === "zoneBased" && "Zone Based"}
                        {e?.pricingListType === "flatBased" && "Flat Based"}
                        {e?.pricingListType === "distanceBased" &&
                          "Distance Based"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => setOpenAddPricingList(true)}
              >
                Add Pricing List
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => {
                  if (!selectedPricingList) {
                    return;
                  }
                  setOpenRemovePricingList(true);
                }}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginLeft: 2, height: "100vh" }}
        />
        <Grid item xs={7.5}>
          {selectedPricingList?.pricingListType === "zoneBased" && (
            <ZoneBased
              onSavePriceList={async () => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  selectedPricingList?.pricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              pricingList={selectedPricingList}
              setSelectedPricingList={setSelectedPricingList}
              serviceOptions={serviceOptions}
            />
          )}
          {selectedPricingList?.pricingListType === "flatBased" && (
            <FlatRate
              onSavePriceList={async () => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  selectedPricingList?.pricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              pricingList={selectedPricingList}
              setSelectedPricingList={setSelectedPricingList}
              serviceOptions={serviceOptions}
            />
          )}
          {selectedPricingList?.pricingListType === "distanceBased" && (
            <DistanceBased
              onSavePriceList={async (newPricingListDetails) => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  newPricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              onRemoveDistanceRange={async (newPricingListDetails) => {
                await updatePricingList(
                  selectedPricingList?.pricingListName,
                  newPricingListDetails,
                  orgSettings?.organizationID,
                  orgSettings?.organizationTablePrefix
                );
                setOpenAlertMessage(true);
                refreshPricingLists();
              }}
              pricingList={selectedPricingList}
              setSelectedPricingList={setSelectedPricingList}
              serviceOptions={serviceOptions}
            />
          )}
        </Grid>
        <AlertDialog
          customControls={false}
          open={openAddPricingList}
          handleClose={() => setOpenAddPricingList(false)}
          // dialogTitle={`New ${label} Attribute`}
          dialogContent={
            <AddPriceList
              zoneSetList={zoneSetList}
              onCancel={() => setOpenAddPricingList(false)}
              onAddPriceList={async (pricingListDetails) => {
                try {
                  if (
                    pricingLists?.findIndex(
                      (e) =>
                        String(e?.pricingListName) ===
                        String(pricingListDetails?.pricingListName)
                    ) === -1 ||
                    pricingLists?.findIndex(
                      (e) =>
                        String(e?.pricingListName) ===
                        String(pricingListDetails?.pricingListName)
                    ) === undefined
                  ) {
                    await addPricingList(
                      pricingListDetails,
                      orgSettings?.organizationID,
                      orgSettings?.organizationTablePrefix
                    );
                    await refreshPricingLists();
                    setOpenAlertMessage(true);
                    setOpenAddPricingList(false);
                  } else {
                    setOpenErrorMessage(true);
                  }
                } catch (error) {
                  console.error(error);
                }
              }}
            />
          }
        />
        <AlertDialog
          open={openRemovePricingList}
          handleClose={() => setOpenRemovePricingList(false)}
          dialogTitle={`Confirm Delete Pricing List: ${selectedPricingList?.pricingListName}?`}
          handleConfirm={async () => {
            try {
              await removePricingList(
                selectedPricingList?.pricingListName,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              await refreshPricingLists();
              setOpenRemovePricingList(false);
              setOpenAlertMessage(true);
              setSelectedPricingList(undefined);
            } catch (error) {
              console.error(error);
            }
          }}
        />
        <Snackbar
          open={openAlertMessage}
          autoHideDuration={6000}
          onClose={() => {
            setOpenAlertMessage(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpenAlertMessage(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Settings saved.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorMessage}
          autoHideDuration={6000}
          onClose={() => {
            setOpenErrorMessage(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpenErrorMessage(false);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            Pricing List already exists.
          </Alert>
        </Snackbar>
      </Grid>
    </VishcorpPricingContext.Provider>
  );
}
