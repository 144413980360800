import { useContext } from "react";
import { VishcorpSettingsContext } from "../context";
import AttributesComponent from "../UI/AttributesComponent";

export default function Customers() {
  const { organizationSettings } = useContext(VishcorpSettingsContext);
  const driverDefaultSettings =
    organizationSettings?.organizationSettings?.driverSettings;

  return (
    <AttributesComponent
      settings={driverDefaultSettings}
      label="Driver"
    />
  );
}
