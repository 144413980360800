import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext } from "react";
import { VishcorpSurchargeContext } from "./context";

export default function SurchargeLists(props) {
  const { surchargeSets, selectedSurchargeSet, setSelectedSurchargeSet } =
    useContext(VishcorpSurchargeContext);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Surcharge Sets</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surchargeSets?.length > 0 ? (
            surchargeSets?.map((e, i) => (
              <TableRow
                key={i}
                hover
                selected={
                  selectedSurchargeSet?.surchargeSetName === e?.surchargeSetName
                }
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedSurchargeSet(e);
                }}
              >
                <TableCell>{e?.surchargeSetName}</TableCell>
              </TableRow>
            ))
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
