import { Grid, Paper } from "@mui/material";
import InvoiceOptions from "./InvoiceOptions";
import { useEffect, useState } from "react";
import CustomerInvoices from "./CustomerInvoices";
import DriverCommissions from "./DriverCommissions";
import InvoiceSettings from "./InvoiceSettings";

export default function Invoices(props) {
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [renderComponent, setRenderComponent] = useState(undefined);

  useEffect(() => {
    if (!selectedOption) {
      return;
    }

    switch (selectedOption) {
      case "Customer Invoices":
        setRenderComponent(<CustomerInvoices />);
        break;

      case "Driver Commissions":
        setRenderComponent(<DriverCommissions />);
        break;

      case "Settings":
        setRenderComponent(<InvoiceSettings />);
        break;

      default:
        break;
    }
  }, [selectedOption]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ height: "100vh", flexGrow: 1, marginTop: 0 }}
    >
      <Grid item xs={2}>
        <Paper sx={{ height: "100%" }}>
          <InvoiceOptions {...{ selectedOption, setSelectedOption }} />
        </Paper>
      </Grid>
      <Grid item xs={10}>
        <Paper sx={{ height: "100%" }}>
          {renderComponent !== undefined && renderComponent}
        </Paper>
      </Grid>
    </Grid>
  );
}
