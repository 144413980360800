export const invoiceOptions = [
  {
    name: "Cancel Time",
    key: "cancelTime",
  },
  {
    name: "Confirm Time",
    key: "confirmTime",
  },
  {
    name: "CostCenter",
    key: "costCenter",
  },
  {
    name: "Delivery City",
    key: "deliveryCity",
  },
  {
    name: "Delivery Contact",
    key: "deliveryContact",
  },
  {
    name: "Delivery Phone",
    key: "deliveryPhone",
  },
  {
    name: "Delivery Time",
    key: "deliveryTime",
  },
  {
    name: "Dispatch Time",
    key: "dispatchTime",
  },
  {
    name: "Driver Number",
    key: "driverNumber",
  },
  {
    name: "Fuel Surcharge",
    key: "fuelSurcharge",
  },
  {
    name: "Invoice Number",
    key: "invoiceNumber",
  },
  {
    name: "Notes",
    key: "notes",
  },
  {
    name: "Number of Pieces",
    key: "pieces",
  },
  {
    name: "Order Date",
    key: "orderDate",
  },
  {
    name: "Order Type",
    key: "orderType",
  },
  {
    name: "Origin",
    key: "origin",
  },
  {
    name: "Package Type",
    key: "packageType",
  },
  {
    name: "Paper Waybill #",
    key: "paperWaybill",
  },
  {
    name: "Pickup City",
    key: "pickupCity",
  },
  {
    name: "Pickup Contact",
    key: "pickupContact",
  },
  {
    name: "Pickup Phone",
    key: "pickupPhone",
  },
  {
    name: "Pickup Time",
    key: "pickupTime",
  },
  {
    name: "Price Breakdown",
    key: "priceBreakdown",
  },
  {
    name: "Ready Time",
    key: "readyTime",
  },
  {
    name: "Receive Time",
    key: "receiveTime",
  },
  {
    name: "Reference #",
    key: "reference",
  },
  {
    name: "Round Trip",
    key: "roundTrip",
  },
  {
    name: "Service Type",
    key: "serviceType",
  },
  {
    name: "Signature",
    key: "signature",
  },
  {
    name: "Special Instructions",
    key: "specialInstructions",
  },
  {
    name: "Time",
    key: "time",
  },
  {
    name: "Waiting Time",
    key: "waitingTime",
  },
  {
    name: "Weight",
    key: "weight",
  },
];
