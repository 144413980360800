export const addDispatcher = async (dispatcherDetails, organizationID) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "ADD_DISPATCHER",
        dispatcherDetails,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateDispatcherAccess = async (
  dispatcherID,
  accessControl,
  organizationID
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_DISPATCHER_ACCESS",
        dispatcherID,
        accessControl,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
