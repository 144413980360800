import {
  Alert,
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useEffect, useState } from "react";
import AddZone from "./AddZone";

export default function ZoneSetDetails(props) {
  const [openZoneDialog, setOpenZoneDialog] = useState(false);
  const { selectedZoneSet, zoneSetDetails, updateZoneSet, refreshZoneSets } =
    props;
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openEditZoneSet, setOpenEditZoneSet] = useState(false);

  const [selectedZone, setSelectedZone] = useState(undefined);
  const [confirmDeleteZone, setConfirmDeleteZone] = useState(undefined);

  useEffect(() => {
    setSelectedZone(undefined);
  }, [selectedZoneSet]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Zone Set: {selectedZoneSet}</Typography>
        <div>
          <Button
            variant="contained"
            size="small"
            sx={{ marginRight: 2 }}
            onClick={() => {
              setOpenZoneDialog(true);
            }}
          >
            Add Zone
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedZone === undefined}
            onClick={() => {
              setConfirmDeleteZone(true);
            }}
          >
            Remove Zone
          </Button>
        </div>
      </div>
      <br />
      <Paper sx={{ height: "70vh" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Zone Name</b>
                </TableCell>
                <TableCell>
                  <b>Included Citites</b>
                </TableCell>
                <TableCell>
                  <b>Included Postal Codes</b>
                </TableCell>
                <TableCell>
                  <b>Included Companies</b>
                </TableCell>
                <TableCell>
                  <b>Included Drivers</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zoneSetDetails?.zones &&
                zoneSetDetails?.zones?.map((e, i) => (
                  <TableRow
                    key={`zone-row-${i}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    selected={selectedZone?.zoneName === e?.zoneName}
                    onClick={() => setSelectedZone(e)}
                    onDoubleClick={() => {
                      setOpenEditZoneSet(true);
                    }}
                  >
                    <TableCell>{e?.zoneName}</TableCell>
                    <TableCell>
                      <div>
                        {e?.includedOptions?.cities &&
                          e?.includedOptions?.cities?.map((p) => (
                            <div>{p?.cityName}</div>
                          ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {e?.includedOptions?.postalCodes &&
                          e?.includedOptions?.postalCodes?.map((p) => (
                            <div>{p?.postalCode}</div>
                          ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {e?.includedOptions?.companies &&
                          e?.includedOptions?.companies?.map((p) => (
                            <div>{p?.companyName}</div>
                          ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div></div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <AlertDialog
        customControls={false}
        open={openZoneDialog}
        handleClose={() => setOpenZoneDialog(false)}
        maxWidth="xl"
        dialogContent={
          <AddZone
            onCancel={() => setOpenZoneDialog(false)}
            onUpdateZone={(e) => {
              if (
                zoneSetDetails?.zones?.findIndex(
                  (f) => String(f?.zoneName) === String(e?.zoneName)
                ) === -1 ||
                zoneSetDetails?.zones?.findIndex(
                  (f) => String(f?.zoneName) === String(e?.zoneName)
                ) === undefined
              ) {
                updateZoneSet({
                  ...(zoneSetDetails ?? {}),
                  zones: [...(zoneSetDetails?.zones ?? []), e],
                });
                setOpenZoneDialog(false);
              } else {
                setOpenAlertMessage(true);
              }
            }}
            onRefresh={async () => {
              await refreshZoneSets();
            }}
          />
        }
      />
      <AlertDialog
        open={confirmDeleteZone}
        handleClose={() => {
          setConfirmDeleteZone(false);
        }}
        dialogTitle={`Confirm Delete Zone: ${selectedZone?.zoneName}?`}
        handleConfirm={async () => {
          if (selectedZone?.zoneName === undefined) {
            return;
          }
          await updateZoneSet({
            ...(zoneSetDetails ?? {}),
            zones: [
              ...(zoneSetDetails?.zones?.filter(
                (e) => e?.zoneName !== selectedZone?.zoneName
              ) ?? []),
            ],
          });
          setConfirmDeleteZone(false);
        }}
      />

      <AlertDialog
        customControls={false}
        maxWidth="xl"
        open={openEditZoneSet}
        handleClose={() => setOpenEditZoneSet(false)}
        dialogContent={
          <AddZone
            onCancel={() => setOpenEditZoneSet(false)}
            onUpdateZone={async (updatedZone) => {
              const updatedZones = zoneSetDetails?.zones?.map((e) => {
                if (updatedZone?.zoneName === e?.zoneName) {
                  return updatedZone;
                }
                return e;
              });
              await updateZoneSet({
                ...(zoneSetDetails ?? {}),
                zones: [...(updatedZones ?? [])],
              });
              setOpenEditZoneSet(false);
            }}
            isEdit={true}
            zoneDetails={selectedZone}
            onRefresh={async () => {
              await refreshZoneSets();
            }}
          />
        }
      />

      <Snackbar
        open={openAlertMessage}
        autoHideDuration={6000}
        onClose={() => {
          setOpenAlertMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenAlertMessage(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Zone already exists.
        </Alert>
      </Snackbar>
    </div>
  );
}
