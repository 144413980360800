import { Button, Divider, Grid, Typography } from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useContext, useEffect, useState } from "react";
import AddSurchargeSet from "./AddSurchargeSet";
import { VishcorpSurchargeContext } from "./context";
import { getSurchargeSets } from "./functions";
import SurchargeDetails from "./SurchargeDetails";
import SurchargeLists from "./SurchargeLists";
import { VishcorpContext } from "Context";

export default function SurchargeSets(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const [selectedSurchargeSet, setSelectedSurchargeSet] = useState(undefined);
  const [surchargeSets, setSurchargeSets] = useState(undefined);
  const [openAddSurchargeSet, setOpenAddSurchargeSet] = useState(false);

  const refresh = () => {
    getSurchargeSets(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    ).then(setSurchargeSets);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VishcorpSurchargeContext.Provider
      value={{
        selectedSurchargeSet,
        setSelectedSurchargeSet,
        surchargeSetDetails: selectedSurchargeSet?.surchargeSetDetails,
        surchargeSets,
        setSurchargeSets,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h5">Surcharge Lists</Typography>
          <SurchargeLists
            onSurchargeSelect={(e) => {
              setSelectedSurchargeSet(e);
            }}
          />
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setOpenAddSurchargeSet(true);
                }}
              >
                Add Surcharge Set
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="contained" size="small">
                Remove
              </Button>
              <br />
              <Button variant="contained" size="small" disabled>
                Copy
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginLeft: 2, height: "100vh" }}
        />
        <Grid item xs={7.5}>
          {selectedSurchargeSet !== undefined && <SurchargeDetails />}
        </Grid>
        <AlertDialog
          customControls={false}
          open={openAddSurchargeSet}
          handleClose={() => setOpenAddSurchargeSet(false)}
          dialogContent={
            <AddSurchargeSet
              refresh={refresh}
              handleClose={() => {
                setOpenAddSurchargeSet(false);
              }}
            />
          }
        />
      </Grid>
    </VishcorpSurchargeContext.Provider>
  );
}
