import React, { useContext, useEffect, useState } from "react";
import Tab from "Components/Tabs/Tab";
import { primaryButtonColor } from "constants";
import { Button } from "@mui/material";
import { primaryButtonHoverColor } from "constants";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import GlobalUserList from "./GlobalUserList";
import AddGlobalUser from "./AddGlobalUser";
import { getGlobalUsers } from "API/GlobalUsers";
import { VishcorpContext } from "Context";

export default function GlobalUsers(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const organizationID = orgSettings?.organizationID;
  const [openNewGlobalUserDialog, setOpenNewGlobalUserDialog] = useState(false);
  const [globalUsers, setGlobalUsers] = useState(undefined);

  const refreshGlobalUsers = async () => {
    if (!organizationID) {
      return;
    }

    await getGlobalUsers(
      orgSettings?.organizationID,
      orgSettings?.organizationTablePrefix
    )
      .then(setGlobalUsers)
      .catch(console.error);
  };

  useEffect(() => {
    refreshGlobalUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID]);

  return (
    <Tab title="Global Users">
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignContent: "right",
        }}
      >
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          onClick={() => {
            setOpenNewGlobalUserDialog(true);
          }}
        >
          Add Global User
        </Button>
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          onClick={() => {}}
        >
          Delete Global User
        </Button>
      </div>
      <br />
      <GlobalUserList globalUsers={globalUsers} />
      <AlertDialog
        customControls={false}
        open={openNewGlobalUserDialog}
        handleClose={() => setOpenNewGlobalUserDialog(false)}
        dialogTitle={`New Global User`}
        dialogContent={
          <AddGlobalUser
            handleClose={() => setOpenNewGlobalUserDialog(false)}
            refreshGlobalUsers={refreshGlobalUsers}
          />
        }
      />
    </Tab>
  );
}
