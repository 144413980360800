import "./App.css";
import VishcorpAuthenticator from "./Components/Authentication/VishcorpAuthenticator";
import MenuDrawer from "./Components/MenuDrawer/MenuDrawer";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Drivers from "Components/Drivers/Drivers";
import Customers from "Components/Customers/Customers";
import AddDriver from "Components/Drivers/AddDriver";
import AppTemplate from "Templates/AppTemplate";
import { useEffect, useState } from "react";
import { VishcorpContext } from "Context";
import CompanySettings from "Components/CompanySettings";
import { getDriverOrders, getOrganizationSettings } from "API";
import CompletedOrders from "Components/CompletedOrders";
import Invoices from "Components/Invoices";
import { Hub } from "aws-amplify";
import { Auth } from "aws-amplify";
import { getDrivers } from "API/Drivers";

import {
  getActiveOrders,
  getCancelledOrders,
  getConfirmedOrders,
  getDeliveredOrders,
  getIncomingOrders,
  getPickUpOrders,
} from "API/Orders";
import { getOrganizationLogo } from "API/Settings";

function App() {
  const [userInfo, setUserInfo] = useState(undefined);
  const [drivers, setDrivers] = useState(undefined);
  const [pickUpOrders, setPickUpOrders] = useState(undefined);
  const [incomingOrders, setIncomingOrders] = useState(undefined);
  const [allOrders, setAllOrders] = useState(undefined);
  const [activeOrders, setActiveOrders] = useState(undefined);
  const [cancelledOrders, setCancelledOrders] = useState(undefined);
  const [confirmedOrders, setConfirmedOrders] = useState(undefined);
  const [completedOrders, setCompletedOrders] = useState(undefined);
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [selectedDriver, setSelectedDriver] = useState(undefined);
  const [isOptimized, setIsOptimized] = useState(false);

  const [organizationLogo, setOrganizationLogo] = useState(undefined);

  const [dispatcherDetails, setDispatcherDetails] = useState(undefined);

  const [orgSettings, setOrgSettings] = useState(undefined);

  const organizationID = orgSettings?.organizationID;

  const refreshOrganizationSettings = (organizationID) => {
    if (!organizationID) {
      return;
    }
    getOrganizationSettings(organizationID).then((res) => {
      getDrivers(res?.organizationTablePrefix).then((res) =>
        setDrivers(res?.drivers?.Items)
      );
      setOrgSettings(res);
    });
    getOrganizationLogo(organizationID).then((res) => setOrganizationLogo(res));
  };

  const refreshDrivers = () => {
    getDrivers(orgSettings?.organizationTablePrefix).then((res) =>
      setDrivers(res?.drivers?.Items)
    );
  };

  const refreshOrder = async () => {
    if (!orgSettings) {
      return;
    }
    await getIncomingOrders(orgSettings?.organizationTablePrefix).then(
      (res) => {
        setIncomingOrders(res?.incomingOrders?.Items);
      }
    );
    const pPickUpOrders = await getPickUpOrders(
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        setPickUpOrders(res?.pickUpOrders?.Items);
        return res?.pickUpOrders?.Items;
      })
      .catch(console.error);
    const pActiveOrders = await getActiveOrders(
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        setActiveOrders(res?.activeOrders?.Items);
        return res?.activeOrders?.Items;
      })
      .catch(console.error);
    // const pCancelledOrders =
    await getCancelledOrders(orgSettings?.organizationTablePrefix)
      .then((res) => {
        setCancelledOrders(res?.cancelledOrders?.Items);
      })
      .catch(console.error);
    const pConfirmedOrders = await getConfirmedOrders(
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        setConfirmedOrders(res?.confirmedOrders?.Items);
        return res?.confirmedOrders?.Items;
      })
      .catch(console.error);
    // const pCompletedOrders =
    await getDeliveredOrders(orgSettings?.organizationTablePrefix)
      .then((res) => {
        setCompletedOrders(res?.deliveredOrders?.Items);
      })
      .catch(console.error);
    // setCompletedOrders(pCompletedOrders);
    // setCancelledOrders(pCancelledOrders);
    setAllOrders(
      [
        // ...pIncomingOrders,
        ...(pActiveOrders ?? []),
        // ...pCancelledOrders,
        // ...pCompletedOrders,
        ...(pConfirmedOrders ?? []),
        ...(pPickUpOrders ?? []),
      ]?.filter((e) => e?.deliveryDetails?.latitude)
    );
  };

  useEffect(() => {
    setAllOrders([]);
    if (selectedDriver !== undefined) {
      (async () => {
        let pAllOrders = await getDriverOrders(
          selectedDriver,
          orgSettings?.organizationTablePrefix
        )
          .then((res) => {
            // setDriverOrders(res);
            return res;
          })
          .catch(console.error);
        const driverInfo = drivers?.find(
          (e) => selectedDriver === e?.driverNumber
        );

        pAllOrders = pAllOrders
          ?.filter(
            (e) => e?.orderStatus !== "Delivered" && e?.orderStatus !== "Cancel"
          )
          ?.filter((e) => e?.deliveryDetails?.latitude);
        if (
          driverInfo?.driverOptimizedOrders?.optimizedOrders?.length ===
          pAllOrders?.filter((e) => e?.orderStatus !== "Active")?.length
        ) {
          let tempDump =
            driverInfo?.driverOptimizedOrders?.optimizedOrders ?? [];
          pAllOrders = [
            ...tempDump.map((e) => {
              return pAllOrders?.find((a) => a?.orderID === e);
            }),
            ...(pAllOrders ?? [])?.filter((e) => e?.orderStatus === "Active"),
          ];
          setIsOptimized(true);
        } else {
          setIsOptimized(false);
        }
        setAllOrders(pAllOrders);
        const pActiveOrders = pAllOrders?.filter(
          (e) => e?.orderStatus === "Active"
        );
        const pIncomingOrders = await getIncomingOrders(
          orgSettings?.organizationTablePrefix
        ).then((res) => {
          setIncomingOrders(res?.incomingOrders?.Items);
          return res?.incomingOrders?.Items;
        });
        const pCompletedOrders = pAllOrders?.filter(
          (e) => e?.orderStatus === "Delivered"
        );
        const pCancelledOrders = pAllOrders?.filter(
          (e) => e?.orderStatus === "Cancel"
        );
        const pConfirmeddOrders = pAllOrders?.filter(
          (e) => e?.orderStatus === "Confirmed"
        );
        setActiveOrders(pActiveOrders);
        setCompletedOrders(pCompletedOrders);
        setIncomingOrders(pIncomingOrders);
        setCancelledOrders(pCancelledOrders);
        setConfirmedOrders(pConfirmeddOrders);
      })();
    } else {
      refreshOrder();
      setIsOptimized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDriver, organizationID]);

  const getUserInfo = async () => await Auth.currentUserInfo();

  useEffect(() => {
    getUserInfo().then((e) => {
      refreshOrganizationSettings(e?.attributes?.["custom:organizationID"]);
      setUserInfo(e);
    });
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          getUserInfo().then((e) => {
            refreshOrganizationSettings(
              e?.attributes?.["custom:organizationID"]
            );
            setUserInfo(e);
          });
          break;
        case "signUp":
          break;
        case "signOut":
          setUserInfo(undefined);
          break;
        case "signIn_failure":
          break;
        case "configured":
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    (async function () {
      let pUserInfo = await Auth.currentUserInfo();
      refreshOrganizationSettings(
        pUserInfo?.attributes?.["custom:organizationID"]
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    if (userInfo?.attributes?.["custom:isDispatcher"]) {
      setDispatcherDetails(
        orgSettings?.dispatchers?.find(
          (e) =>
            e?.dispatcherID === userInfo?.attributes?.["custom:dispatcherID"]
        )
      );
    }
  }, [userInfo, orgSettings]);

  return (
    <div style={{ background: "#E2F3FB" }}>
      <VishcorpContext.Provider
        value={{
          orgSettings,
          drivers,
          pickUpOrders,
          allOrders,
          incomingOrders,
          activeOrders,
          cancelledOrders,
          confirmedOrders,
          completedOrders,
          selectedOrder,
          selectedDriver,
          isOptimized,
          userInfo,
          organizationLogo,
          refreshDrivers,
          refreshOrder,
          setSelectedOrder,
          setSelectedDriver,
          setAllOrders,
          setIsOptimized,
          setUserInfo,
          refreshOrganizationSettings,
        }}
      >
        <VishcorpAuthenticator>
          <BrowserRouter>
            <MenuDrawer>
              <Routes>
                <Route path="/" element={<AppTemplate />} />
                {/* <Route
                  path="/"
                  element={<Navigate to="/Orders" replace={true} />}
                />*/}
                {(dispatcherDetails?.accessControl?.drivers ||
                  dispatcherDetails === undefined) && (
                  <Route path="/addDriver" element={<AddDriver />} />
                )}

                <Route path="/Orders" element={<AppTemplate />} />
                {/* <Route path="/Orders" element={<Orders />} /> */}
                {(dispatcherDetails?.accessControl?.drivers ||
                  dispatcherDetails === undefined) && (
                  <Route path="/Drivers" element={<Drivers />} />
                )}
                {(dispatcherDetails?.accessControl?.customers ||
                  dispatcherDetails === undefined) && (
                  <Route path="/Customers" element={<Customers />} />
                )}
                {(dispatcherDetails?.accessControl?.settings ||
                  dispatcherDetails === undefined) && (
                  <Route path="/Settings" element={<CompanySettings />} />
                )}
                {(dispatcherDetails?.accessControl?.completedOrders ||
                  dispatcherDetails === undefined) && (
                  <Route
                    path="/CompletedOrders"
                    element={<CompletedOrders />}
                  />
                )}
                {(dispatcherDetails?.accessControl?.drivers ||
                  dispatcherDetails === undefined) && (
                  <Route path="/Invoices" element={<Invoices />} />
                )}

                {(dispatcherDetails?.accessControl?.settings ||
                  dispatcherDetails === undefined) && (
                  <Route path="/Discover" element={<CompanySettings />} />
                )}
              </Routes>
            </MenuDrawer>
          </BrowserRouter>
          {/* <OnlineDrivers /> */}
        </VishcorpAuthenticator>
      </VishcorpContext.Provider>
    </div>
  );
}

export default App;
