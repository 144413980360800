import {
  Button,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import { updateGeneralSettings, updateOrganizationLogo } from "API/Settings";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { VishcorpContext } from "Context";
import { useContext, useEffect, useState } from "react";

export default function GeneralSettings(props) {
  const { orgSettings, refreshOrganizationSettings, organizationLogo } =
    useContext(VishcorpContext);
  const [generalSettings, setGeneralSettings] = useState(
    orgSettings?.generalSettings ?? {
      organizationDisplayName: "",
      // organizationLogo: "",
    }
  );

  const [pOrganizationLogo, setOrganizationLogo] = useState(undefined);

  const [openSaveGeneralSettings, setOpenSaveGeneralSettings] = useState(false);

  const onGeneralSettingsChange = (name, e) => {
    setGeneralSettings((pre) => ({
      ...pre,
      [name]: e,
    }));
  };

  useEffect(() => {
    if (!organizationLogo?.data) return;
    setOrganizationLogo(
      `data:image/png;base64, ${
        JSON.parse(new Buffer.from(organizationLogo?.data).toString())?.data
      }`
    );
  }, [organizationLogo]);

  const convertToBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setOrganizationLogo(reader.result);
      // onGeneralSettingsChange("organizationLogo", reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <Paper sx={{ marginLeft: 2, height: "100%", padding: 4 }}>
      <Typography variant="h5">General Settings</Typography>
      <br />
      <div style={{ height: "90%" }}>
        <TextField
          label="Organization Display Name"
          value={generalSettings?.organizationDisplayName}
          size="small"
          style={{ width: "50%" }}
          onChange={(e) => {
            onGeneralSettingsChange(
              "organizationDisplayName",
              e?.target?.value
            );
          }}
        />

        <div style={{ marginTop: 30 }}>
          <FormControlLabel
            label={<b>Organization Logo</b>}
            labelPlacement="start"
            style={{ marginLeft: -1 }}
            control={
              <div style={{ marginLeft: 20 }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    try {
                      convertToBase64(e?.target?.files[0]);
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                />
              </div>
            }
          />
          <br />
          {pOrganizationLogo ? (
            <img
              src={pOrganizationLogo}
              alt="No Logo"
              style={{ height: "120px" }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpenSaveGeneralSettings(true);
          }}
        >
          Save Changes
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {}}
          style={{ marginLeft: 10 }}
        >
          Cancel
        </Button>
      </div>
      <AlertDialog
        open={openSaveGeneralSettings}
        dialogTitle="Update Company Settings?"
        handleClose={() => {
          setOpenSaveGeneralSettings(false);
        }}
        handleConfirm={async () => {
          await updateGeneralSettings(
            generalSettings,
            orgSettings?.organizationID,
            orgSettings?.organizationName
          );
          await updateOrganizationLogo(
            orgSettings?.organizationID,
            pOrganizationLogo
          );
          refreshOrganizationSettings(orgSettings?.organizationID);
          setOpenSaveGeneralSettings(false);
        }}
      />
    </Paper>
  );
}
