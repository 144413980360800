import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { VishcorpContext } from "Context";
import { useContext, useState } from "react";
import AddDispatcher from "./AddDispatcher";
import DispatcherDetails from "./DispatcherDetails";

export default function Dispatchers(props) {
  const { orgSettings, refreshOrganizationSettings } =
    useContext(VishcorpContext);
  const [onAddDipatcher, setOnAddDispatcher] = useState(false);
  const [selectedDispatcher, setSelectedDispatcher] = useState(undefined);
  const [openDispatcherDetails, setOpenDispatcherDetails] = useState(false);

  const dispatchers = orgSettings?.dispatchers;

  return (
    <Paper style={{ marginLeft: 5, padding: 5, height: "100%" }}>
      <Typography variant="h5">Dispatchers</Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignContent: "right",
        }}
      >
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
          }}
          onClick={() => {
            setOnAddDispatcher(true);
          }}
        >
          Add Dispatcher
        </Button>
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
          }}
          onClick={() => {}}
        >
          Delete Dispatcher
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dispatchers?.length > 0 ? (
              dispatchers?.map((e, i) => (
                <TableRow
                  key={i}
                  hover
                  selected={
                    selectedDispatcher?.dispatcherID === e?.dispatcherID
                  }
                  onDoubleClick={() => {
                    setSelectedDispatcher(e);
                    setOpenDispatcherDetails(true);
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell>{e?.dispatcherName}</TableCell>
                  <TableCell>{e?.email}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        dialogTitle="Add Dispatcher"
        customControls={false}
        dialogContent={
          <AddDispatcher
            handleClose={() => setOnAddDispatcher(false)}
            refresh={() => {
              refreshOrganizationSettings(orgSettings?.organizationID);
              setOnAddDispatcher(false);
            }}
          />
        }
        open={onAddDipatcher}
        handleClose={() => setOnAddDispatcher(false)}
      />
      <AlertDialog
        customControls={false}
        open={openDispatcherDetails}
        dialogTitle="Dispatcher Details"
        dialogContent={
          <DispatcherDetails
            dispatcherDetails={selectedDispatcher}
            handleClose={() => setOpenDispatcherDetails(false)}
          />
        }
        handleClose={() => setOpenDispatcherDetails(false)}
      />
    </Paper>
  );
}
