export const AttributeTypes = [
  "String",
  "Address",
  "Number",
  "Phone",
  "Email",
  "Options",
  "Radio",
  "Checkbox",
];
