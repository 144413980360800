import {
  Button,
  //  , IconButton
} from "@mui/material";
// import LedBulb from "./LedBulb";

export default function DriverButton(props) {
  const { driverInfo, active, onClick } = props;
  return (
    <div
      style={{
        height: 100,
        width: 70,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        background: active ? "#FEFFD6" : "white",
      }}
    >
      <Button
        size="small"
        variant={active ? "outlined" : "contained"}
        sx={{
          display: "flex",
          flexDirection: "column",
          fontSize: 12,
          width: 60,
          height: 60,
          borderRadius: 0,
          border: "1px solid",
          borderColor: "primary.main",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        onClick={onClick}
      >
        {driverInfo?.driverName}
      </Button>
      {driverInfo?.driverOrderCount}
    </div>
  );
}
