import { Grid } from "@mui/material";
import AutoDriverDispatch from "./AutoDriverDispatch";
import IncludedCities from "./IncludedCities";
import IncludedCompanies from "./IncludedCompanies";
import IncludedPostalCodes from "./IncludedPostalCodes";
import MapZones from "./MapZones";

export default function IncludeOptions(props) {
  const { includedOptions, setIncludedOptions } = props;

  return (
    <div style={{ width: 1400 }}>
      <Grid container spacing={2}>
        <Grid item xs={2.4}>
          <IncludedCities
            onAddCity={(cityName, cityDetails) => {
              setIncludedOptions((pre) => {
                return {
                  ...(pre ?? {}),
                  cities: pre?.cities
                    ? [...pre?.cities, { cityName, cityDetails }]
                    : [{ cityName, cityDetails }],
                };
              });
            }}
            onRemoveCity={(cityName) => {
              setIncludedOptions((pre) =>
                pre?.cities?.filter((e) => {
                  return e?.cityName === cityName;
                })
              );
            }}
            cities={includedOptions?.cities}
          />
        </Grid>
        <Grid item xs={2.4}>
          <IncludedPostalCodes
            onAddPostalCode={(postalCode) => {
              console.log(postalCode, includedOptions);
              setIncludedOptions((pre) => {
                return {
                  ...(pre ?? {}),
                  postalCodes: pre?.postalCodes
                    ? [...pre?.postalCodes, { postalCode }]
                    : [{ postalCode }],
                };
              });
            }}
            onRemovePostalCode={(postalCode) => {
              setIncludedOptions((pre) =>
                pre?.postalCodes?.filter((e) => {
                  return e?.postalCode === postalCode;
                })
              );
            }}
            postalCodes={includedOptions?.postalCodes}
          />
        </Grid>
        <Grid item xs={2.4}>
          <IncludedCompanies
            onAddCompany={(companyName) => {
              setIncludedOptions((pre) => {
                return {
                  ...(pre ?? {}),
                  companies: pre?.companies
                    ? [...pre?.companies, { companyName }]
                    : [{ companyName }],
                };
              });
            }}
            onRemoveCompany={(companyName) => {
              setIncludedOptions((pre) =>
                pre?.companies?.filter((e) => {
                  return e?.companyName === companyName;
                })
              );
            }}
            companies={includedOptions?.companies}
          />
        </Grid>
        <Grid item xs={2.4}>
          <MapZones onAddMapZone={() => {}} onRemoveMapZone={() => {}} />
        </Grid>
        <Grid item xs={2.4}>
          <AutoDriverDispatch
            onAddDriver={() => {}}
            onRemoveDriver={() => {}}
          />
        </Grid>
      </Grid>
    </div>
  );
}
