import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Typography,
  IconButton,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { useContext, useState } from "react";
import { VishcorpSettingsContext } from "../context";
import { deleteServiceOption } from "./functions";
import NewServiceOption from "./NewServiceOption";

export default function ServiceOptions(props) {
  const { organizationSettings, updateOrganizationSettings } = useContext(
    VishcorpSettingsContext
  );

  const organizationID = organizationSettings?.organizationID;
  const [openNewServiceOption, setOpenNewServiceOption] = useState(undefined);
  const [confirmDelete, setConfirmDelete] = useState(undefined);
  const serviceOptions = organizationSettings?.serviceOptions;
  const [selectedOption, setSelectedOption] = useState(undefined);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Paper sx={{ padding: 2, marginLeft: 1, flexGrow: 1 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">{`Service Options`}</Typography>
          <Button
            onClick={() => setOpenNewServiceOption(true)}
            variant="contained"
          >
            New Service Option
          </Button>
        </div>
        <br />
        <TableContainer
          component={Paper}
          style={{ height: "50vh", overflow: "scroll" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Service Name</b>
                </TableCell>
                <TableCell>
                  <b>Delivery Time</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceOptions?.length > 0 ? (
                serviceOptions?.map((e) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{e?.name}</TableCell>
                    <TableCell>
                      {e?.deliveryTime?.days || e?.deliveryTime?.hours
                        ? `${e?.deliveryTime?.days ?? 0} Days ${
                            e?.deliveryTime?.hours ?? 0
                          } Hours`
                        : ""}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setSelectedOption(e);
                          setConfirmDelete(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    No Records
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <AlertDialog
        customControls={false}
        open={openNewServiceOption}
        handleClose={() => setOpenNewServiceOption(false)}
        // dialogTitle={`New ${label} Attribute`}
        dialogContent={
          <NewServiceOption
            handleClose={() => setOpenNewServiceOption(false)}
            organizationID={organizationID}
            onRefresh={updateOrganizationSettings}
          />
        }
      />
      <AlertDialog
        // customControls={false}
        open={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        handleConfirm={async () => {
          await deleteServiceOption(
            organizationID,
            selectedOption?.name,
            organizationSettings?.organizationTablePrefix
          );
          setConfirmDelete(false);
          updateOrganizationSettings();
        }}
        dialogTitle={`Delete Service Option: ${selectedOption?.name}?`}
      />
    </Box>
  );
}
