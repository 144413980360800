export const generatePDF = (bufferArray, fileName = "Invoice") => {
  if (!bufferArray) {
    return;
  }
  const url = window.URL.createObjectURL(
    new Blob([new Uint8Array(bufferArray).buffer])
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName + ".pdf");
  document.body.appendChild(link);
  link.click();
};
