import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import NumberInput from "Templates/UI/Input/NumberInput";

export default function AddMiscCharge(props) {
  const { handleClose, onSaveMiscCharges } = props;
  const [chargeName, setChargeName] = useState(undefined);
  const [chargeAmount, setChargeAmount] = useState(undefined);
  const [commissionFrom, setCommissionFrom] = useState("fromDriver");
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Add Misc Charge</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          fullWidth
          label="Charge Name"
          value={chargeName}
          onChange={(e) => {
            setChargeName(e?.target?.value);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          value={chargeAmount ?? ""}
          setValue={(e) => {
            setChargeAmount(e);
          }}
          label={"Charge Amount"}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          style={{ width: "100%", marginRight: 10 }}
          size="small"
        >
          <InputLabel id="price-list-label">
            Commission Percentage From
          </InputLabel>
          <Select
            fullWidth
            value={commissionFrom ?? ""}
            size="small"
            label="Commission Percentage From"
            onChange={(e) => {
              setCommissionFrom(e?.target?.value);
            }}
          >
            <MenuItem value="fromDriver">From Driver</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (!chargeName && !chargeAmount && !!commissionFrom) {
                return;
              }
              onSaveMiscCharges({ chargeName, chargeAmount, commissionFrom });
              handleClose();
            }}
            disabled={!chargeName || !chargeAmount || !commissionFrom}
          >
            Save and Close
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Exit
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
