import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { VishcorpSettingsContext } from "Components/CompanySettings/context";
import { useContext, useState } from "react";
import AddDistanceRange from "./AddDistanceRange";
import { VishcorpPricingContext } from "./context";

export default function DistanceBased(props) {
  const { pricingLists } = useContext(VishcorpPricingContext);
  const { organizationSettings } = useContext(VishcorpSettingsContext);
  const {
    serviceOptions,
    pricingList,
    onSavePriceList,
    onRemoveDistanceRange,
    setSelectedPricingList,
  } = props;
  const packageTypes = organizationSettings?.packageTypes;

  const [openAddDistanceRange, setOpenAddDistanceRange] = useState(undefined);
  const [selectedRange, setSelectedRange] = useState(undefined);
  const [selectedPackageType, setSelectedPackageType] = useState("Baggage");
  const [selectedService, setSelectedService] = useState("Urgent");

  const updatePriceMatrix = (value, type) => {
    setSelectedRange((pre) => {
      return {
        ...(pre ?? {}),
        priceMatrix: {
          ...(selectedRange?.priceMatrix ?? {}),
          [selectedPackageType]: {
            ...(selectedRange?.priceMatrix?.[selectedPackageType] ?? {}),
            [selectedService]: {
              ...(selectedRange?.priceMatrix?.[selectedPackageType]?.[
                selectedService
              ] ?? {}),
              [type]: value,
            },
          },
        },
      };
    });
  };

  return (
    <Grid container ml={1} spacing={2}>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          style={{ width: "100%", marginRight: 10 }}
          size="small"
        >
          <InputLabel>Rollover Pricelist:</InputLabel>
          <Select
            fullWidth
            size="small"
            label="Rollover Pricelist:"
            value={pricingList?.pricingListDetails?.backUpPricingList ?? ""}
            onChange={(e) => {
              setSelectedPricingList((pre) => ({
                ...(pre ?? {}),
                pricingListDetails: {
                  ...(pre?.pricingListDetails ?? {}),
                  backUpPricingList: e?.target?.value,
                },
              }));
            }}
          >
            {pricingLists?.length > 0 &&
              pricingLists
                ?.filter(
                  (e) => e?.pricingListName !== pricingList?.pricingListName
                )
                ?.map((e, i) => (
                  <MenuItem key={i} value={e?.pricingListName}>
                    {e?.pricingListName}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TableContainer component={Paper} sx={{ height: "70vh" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>From</b>
                </TableCell>
                <TableCell>
                  <b>To</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricingList?.pricingListDetails?.distancePricingList?.length >
              0 ? (
                pricingList?.pricingListDetails?.distancePricingList?.map(
                  (e) => (
                    <TableRow
                      hover
                      selected={selectedRange?.from === e?.from}
                      onClick={() => setSelectedRange(e)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{e?.from}</TableCell>
                      <TableCell>{e?.to}</TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => {
              setOpenAddDistanceRange(true);
            }}
          >
            Add Range
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(pricingList));
              onRemoveDistanceRange({
                ...temp?.pricingListDetails,
                distancePricingList:
                  temp?.pricingListDetails?.distancePricingList?.filter(
                    (e) =>
                      e?.from !== selectedRange?.from &&
                      e?.to !== selectedRange?.to
                  ),
              });
              setSelectedRange(undefined);
            }}
          >
            Remove Range
          </Button>
        </div>
      </Grid>
      {selectedRange !== undefined && (
        <Grid item xs={8}>
          <Typography>
            <b>
              {selectedRange?.from !== undefined &&
                `${selectedRange?.from} kms`}{" "}
              to {selectedRange?.to !== undefined && `${selectedRange?.to} kms`}
            </b>
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Package Type</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packageTypes?.length > 0 &&
                      packageTypes?.map((e) => (
                        <TableRow
                          hover
                          selected={selectedPackageType === e?.name}
                          onClick={() => {
                            setSelectedPackageType(e?.name);
                          }}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell>{e?.name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Services</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serviceOptions?.length > 0 &&
                      serviceOptions?.map((e) => (
                        <TableRow
                          hover
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          selected={selectedService === e?.name}
                          onClick={() => {
                            setSelectedService(e?.name);
                          }}
                        >
                          <TableCell>{e?.name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <br />
          <Typography>
            Distance settings for:{" "}
            <b>
              {selectedPackageType} on {selectedService}
            </b>
          </Typography>
          <br />
          <Grid container>
            <Grid item xs={6}>
              <FormControl>
                <TextField
                  variant="standard"
                  size="small"
                  label="Minimum"
                  value={
                    selectedRange?.priceMatrix?.[selectedPackageType]?.[
                      selectedService
                    ]?.minimum
                  }
                  onChange={(e) => {
                    const regex = /^(\d)*(\.)?([0-9]{1})?$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      updatePriceMatrix(e?.target?.value, "minimum");
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <br />
                <TextField
                  variant="standard"
                  size="small"
                  label="Base Price"
                  value={
                    selectedRange?.priceMatrix?.[selectedPackageType]?.[
                      selectedService
                    ]?.basePrice
                  }
                  onChange={(e) => {
                    const regex = /^(\d)*(\.)?([0-9]{1})?$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      updatePriceMatrix(e?.target?.value, "basePrice");
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <br />
                <TextField
                  variant="standard"
                  size="small"
                  label="Price/km"
                  value={
                    selectedRange?.priceMatrix?.[selectedPackageType]?.[
                      selectedService
                    ]?.priceKM
                  }
                  onChange={(e) => {
                    const regex = /^(\d)*(\.)?([0-9]{1})?$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      updatePriceMatrix(e?.target?.value, "priceKM");
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <RadioGroup
                  value={
                    selectedRange?.priceMatrix?.[selectedPackageType]?.[
                      selectedService
                    ]?.zoneSetOptions ?? ""
                  }
                  onChange={(e) => {
                    updatePriceMatrix(e?.target.value, "zoneSetOptions");
                  }}
                >
                  <FormControlLabel
                    value="allZones"
                    control={<Radio />}
                    label="Apply to all zones"
                  />
                  <FormControlLabel
                    value="specifiedZone"
                    control={<Radio />}
                    label="Apply to specified zone set"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                onSavePriceList({
                  ...(pricingList?.pricingListDetails ?? {}),
                  distancePricingList:
                    pricingList?.pricingListDetails?.distancePricingList?.map(
                      (e) => {
                        if (
                          selectedRange?.from === e?.from &&
                          selectedRange?.to === e?.to
                        ) {
                          return selectedRange;
                        }
                        return e;
                      }
                    ),
                });
              }}
              style={{ marginRight: 10 }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedRange(
                  pricingList?.pricingListDetails?.distancePricingList?.find(
                    (e) =>
                      e?.from === selectedRange?.from &&
                      e?.to === selectedRange?.to
                  )
                );
              }}
            >
              Reset
            </Button>
          </div>
        </Grid>
      )}
      <AlertDialog
        customControls={false}
        open={openAddDistanceRange}
        handleClose={() => setOpenAddDistanceRange(false)}
        dialogContent={
          <AddDistanceRange
            onClose={() => setOpenAddDistanceRange(false)}
            onSave={async (newDistanceRange) => {
              const validationError = (
                pricingList?.pricingListDetails?.distancePricingList ?? []
              )?.some((e) => {
                return (
                  (parseFloat(newDistanceRange?.from) >= parseFloat(e?.from) &&
                    parseFloat(newDistanceRange?.from) <= parseFloat(e?.to)) ||
                  (parseFloat(newDistanceRange?.to) >= parseFloat(e?.from) &&
                    parseFloat(newDistanceRange?.to) <= parseFloat(e?.to)) ||
                  (parseFloat(newDistanceRange?.from) <= parseFloat(e?.from) &&
                    parseFloat(newDistanceRange?.to) >= parseFloat(e?.to))
                );
              });
              if (!validationError) {
                onSavePriceList({
                  ...(pricingList?.pricingListDetails ?? {}),
                  distancePricingList: [
                    ...(pricingList?.pricingListDetails?.distancePricingList ??
                      []),
                    { ...newDistanceRange },
                  ],
                });
                setOpenAddDistanceRange(false);
              }
            }}
          />
        }
      />
    </Grid>
  );
}
