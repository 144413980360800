import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { VishcorpSettingsContext } from "Components/CompanySettings/context";
import { useContext, useEffect, useState } from "react";
import NumberInput from "Templates/UI/Input/NumberInput";
import { VishcorpSurchargeContext } from "./context";

export default function AddGeneralCharge(props) {
  const { surchargeSetDetails } = useContext(VishcorpSurchargeContext);
  const { onSaveGeneralCharges, handleClose } = props;
  const { organizationSettings } = useContext(VishcorpSettingsContext);

  const [selectedVehicleType, setSelectedVehicleType] = useState(undefined);
  const [selectedPackageType, setSelectedPackageType] = useState(undefined);
  const [selectedServiceOption, setSelectedServiceOption] = useState(undefined);

  const { packageTypes, serviceOptions, vehicleTypes } = organizationSettings;

  const [generalCharge, setGeneralCharge] = useState(undefined);

  const generalCharges = surchargeSetDetails?.generalCharges;

  useEffect(() => {
    if (
      generalCharges &&
      selectedVehicleType &&
      selectedPackageType &&
      selectedServiceOption
    ) {
      setGeneralCharge(
        generalCharges?.[selectedServiceOption]?.[selectedPackageType]?.[
          selectedVehicleType
        ]
      );
    }
  }, [
    generalCharges,
    selectedVehicleType,
    selectedPackageType,
    selectedServiceOption,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FormControl
            fullWidth
            style={{ width: "100%", marginRight: 10 }}
            size="small"
          >
            <InputLabel id="price-list-label">Apply to Service</InputLabel>
            <Select
              fullWidth
              value={selectedServiceOption ?? ""}
              size="small"
              label="Apply to Service"
              onChange={(e) => {
                setSelectedServiceOption(e?.target?.value);
              }}
            >
              {serviceOptions?.length > 0 &&
                [{ name: "All Services" }, ...serviceOptions]?.map((e, i) => (
                  <MenuItem key={i} value={e?.name}>
                    {e?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{ width: "100%", marginRight: 10 }}
            size="small"
          >
            <InputLabel id="price-list-label">Apply to Package type</InputLabel>
            <Select
              fullWidth
              value={selectedPackageType ?? ""}
              size="small"
              label="Apply to Package type"
              onChange={(e) => {
                setSelectedPackageType(e?.target?.value);
              }}
            >
              {packageTypes?.length > 0 &&
                [{ name: "All Package Types" }, ...packageTypes]?.map(
                  (e, i) => (
                    <MenuItem key={i} value={e?.name}>
                      {e?.name}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ width: "100%" }} size="small">
            <InputLabel id="price-list-label">Apply to Vehicle type</InputLabel>
            <Select
              fullWidth
              value={selectedVehicleType ?? ""}
              size="small"
              label="Apply to Vehicle type"
              onChange={(e) => {
                setSelectedVehicleType(e?.target?.value);
              }}
            >
              {vehicleTypes?.length > 0 &&
                [{ name: "All Vehicle Types" }, ...vehicleTypes]?.map(
                  (e, i) => (
                    <MenuItem key={i} value={e?.name}>
                      {e?.name}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Surcharge Name"
          value={generalCharge?.surchargeName ?? ""}
          onChange={(e) =>
            setGeneralCharge((pre) => ({
              ...(pre ?? {}),
              surchargeName: e?.target?.value,
            }))
          }
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            row
            value={generalCharge?.chargeType ?? ""}
            onChange={(e) => {
              setGeneralCharge((pre) => ({
                ...(pre ?? {}),
                chargeType: e?.target?.value,
                value:
                  e?.target?.value ===
                  generalCharges?.[selectedServiceOption]?.[
                    selectedPackageType
                  ]?.[selectedVehicleType]?.chargeType
                    ? generalCharges?.[selectedServiceOption]?.[
                        selectedPackageType
                      ]?.[selectedVehicleType]?.value
                    : undefined,
                timed:
                  e?.target?.value ===
                  generalCharges?.[selectedServiceOption]?.[
                    selectedPackageType
                  ]?.[selectedVehicleType]?.chargeType
                    ? generalCharges?.[selectedServiceOption]?.[
                        selectedPackageType
                      ]?.[selectedVehicleType]?.timed
                    : false,
              }));
            }}
          >
            <FormControlLabel value="flat" control={<Radio />} label="Flat" />
            <FormControlLabel
              value="distanceCharge"
              control={<Radio />}
              label="Distance Charge"
            />
            <FormControlLabel
              value="percentageBase"
              control={<Radio />}
              label="Percentage of Base Price"
            />
            <FormControlLabel
              value="percentageTotal"
              control={<Radio />}
              label="Percentage of Total Price"
            />
            <FormControlLabel value="tax" control={<Radio />} label="Tax" />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <NumberInput
              type={
                ["percentageBase", "percentageTotal", "tax"].includes(
                  generalCharge?.chargeType
                )
                  ? "percentage"
                  : ""
              }
              value={generalCharge?.value ?? ""}
              setValue={(e) => {
                setGeneralCharge((pre) => {
                  return {
                    ...pre,
                    value: e,
                  };
                });
              }}
              label={
                ["percentageBase", "percentageTotal", "tax"].includes(
                  generalCharge?.chargeType
                )
                  ? "Percentage"
                  : "Price"
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={generalCharge?.timed}
                  onChange={(e) => {
                    setGeneralCharge((pre) => ({
                      ...pre,
                      timed: e?.target?.checked,
                    }));
                  }}
                />
              }
              label="Timed"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onSaveGeneralCharges({
                ...(generalCharges ?? {}),
                [selectedServiceOption]: {
                  ...(generalCharges?.[selectedServiceOption] ?? {}),
                  [selectedPackageType]: {
                    ...(generalCharges?.[selectedServiceOption]?.[
                      selectedPackageType
                    ] ?? {}),
                    [selectedVehicleType]: generalCharge,
                  },
                },
              });
              handleClose();
            }}
            disabled={
              !(
                selectedPackageType &&
                selectedServiceOption &&
                selectedVehicleType &&
                generalCharge?.surchargeName
              )
            }
          >
            Save and Close
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClose()}
          >
            Exit
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
