import { Button, Typography } from "@mui/material";
import { useState } from "react";
import NumberInput from "Templates/UI/Input/NumberInput";

export default function AddWeightValues(props) {
  const {
    selectedPackageType,
    selectedVehicleType,
    selectedServiceOption,
    addWeight,
    handleClose,
  } = props;
  const [weightValues, setWeightValues] = useState(undefined);
  return (
    <div>
      <Typography variant="h5" fontWeight={650}>
        Add Weight Surcharge
      </Typography>
      <br />
      <NumberInput
        // defaultValue={}
        // id={}
        // register={register}
        // errors={errors}
        value={weightValues?.from ?? ""}
        setValue={(e) => {
          setWeightValues((pre) => {
            return {
              ...pre,
              from: e,
            };
          });
        }}
        label={"From (kg)"}
      />
      <br />
      <br />
      <NumberInput
        value={weightValues?.to ?? ""}
        setValue={(e) => {
          setWeightValues((pre) => {
            return {
              ...pre,
              to: e,
            };
          });
        }}
        label={"To (kg)"}
      />
      <br />
      <br />
      <NumberInput
        value={weightValues?.price ?? ""}
        setValue={(e) => {
          setWeightValues((pre) => {
            return {
              ...pre,
              price: e,
            };
          });
        }}
        label={"Price"}
      />
      <br />
      <br />
      <NumberInput
        value={weightValues?.kg ?? ""}
        setValue={(e) => {
          setWeightValues((pre) => {
            return {
              ...pre,
              kg: e,
            };
          });
        }}
        label={"kg"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginTop: 15,
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{ marginRight: 10 }}
          onClick={async () => {
            if (
              weightValues?.from &&
              weightValues?.to &&
              weightValues?.price &&
              weightValues?.kg &&
              parseFloat(weightValues?.from) < parseFloat(weightValues?.to)
            ) {
              await addWeight(weightValues, {
                selectedPackageType,
                selectedServiceOption,
                selectedVehicleType,
              });
              handleClose();
            }
          }}
        >
          Add
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
