import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import AlertDialog from "Components/AlertDialog/AlertDialog";

export default function AttributeTable(props) {
  const { settings, handleDeleteAttribute, handleSwitchVisibility } = props;
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState(undefined);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%", flexGrow: 1 }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <b>Name</b>
            </TableCell>
            <TableCell align="center">
              <b>Type</b>
            </TableCell>
            <TableCell align="center">
              <b>Default Value</b>
            </TableCell>
            <TableCell align="center">
              <b>Required</b>
            </TableCell>
            <TableCell align="center">
              <b>Action</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {settings?.map((e, i) => {
            return (
              <TableRow key={`SettingsAttrributeRow${i}`}>
                <TableCell align="center">{e?.name}</TableCell>
                <TableCell align="center">{e?.type}</TableCell>
                <TableCell align="center">{e?.defaultValue ?? "-"}</TableCell>
                <TableCell align="center">
                  <Checkbox checked={e?.required} />
                </TableCell>
                <TableCell align="center">
                  <div>
                    <Button disabled={e?.isDefault}>Edit</Button>
                    <IconButton
                      disabled={e?.isDefault}
                      onClick={() => {
                        setUpdateItem({
                          index: i,
                          data: e,
                          action: "switchVisibility",
                        });
                        setConfirmUpdate(true);
                      }}
                    >
                      {e?.isDefault || e?.visible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                    <IconButton
                      disabled={e?.isDefault}
                      onClick={() => {
                        setUpdateItem({ index: i, data: e, action: "delete" });
                        setConfirmUpdate(true);
                      }}
                    >
                      <DeleteForeverIcon
                        color={e?.isDefault ? "disabled" : "error"}
                      />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <AlertDialog
        open={confirmUpdate}
        handleClose={() => setConfirmUpdate(false)}
        handleConfirm={() => {
          switch (updateItem?.action) {
            case "delete":
              handleDeleteAttribute(updateItem);
              setConfirmUpdate(false);
              break;
            case "switchVisibility":
              handleSwitchVisibility(updateItem);
              setConfirmUpdate(false);
              break;
            default:
              break;
          }
        }}
        dialogTitle={
          updateItem?.action === "delete"
            ? `Confirm Delete: ${updateItem?.data?.name}?`
            : `Confirm Switch Visibility: ${
                updateItem?.data?.visible ? "Visible" : "Hidden"
              } to ${!updateItem?.data?.visible ? "Visible" : "Hidden"}?`
        }
      />
    </TableContainer>
  );
}
