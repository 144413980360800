import { Box, Button, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { newVehicleType } from "./functions";
import { VishcorpContext } from "Context";

export default function NewVehicleType(props) {
  const { handleClose, organizationID, onRefresh } = props;
  const [newVehicle, setNewVehicle] = useState(undefined);
  const { orgSettings } = useContext(VishcorpContext);
  const onSubmit = async () => {
    if (newVehicle?.name) {
      await newVehicleType(
        organizationID,
        newVehicle,
        orgSettings?.organizationTablePrefix
      );
      handleClose();
      onRefresh();
    }
  };
  return (
    <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      {/* <Paper sx={{ flexGrow: 1 }}> */}
      <Typography variant="h5">{`New Vehicle Type`}</Typography>
      <br />
      <div>
        <TextField
          fullWidth
          size="small"
          value={newVehicle?.name}
          label="Name"
          variant="outlined"
          onChange={(e) => {
            setNewVehicle((pre) => ({
              ...pre,
              name: e?.target?.value,
            }));
          }}
          style={{ width: "100%", marginBottom: 15 }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "colmn",
            // justifyContent: "space-between",
          }}
        >
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            onClick={onSubmit}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Box>
  );
}
