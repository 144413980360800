import {
  Box,
  Button,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { newServiceOption } from "./functions";
import { VishcorpContext } from "Context";

export default function NewServiceOption(props) {
  const { orgSettings } = useContext(VishcorpContext);
  console.log(orgSettings);
  const { handleClose, organizationID, onRefresh } = props;
  const [newService, setNewService] = useState({
    name: undefined,
    deliveryTime: {
      days: 0,
      hours: 0,
    },
  });
  const onSubmit = async () => {
    if (newService?.name && newService?.deliveryTime) {
      await newServiceOption(
        organizationID,
        newService,
        orgSettings?.organizationTablePrefix
      );
      handleClose();
      onRefresh();
    }
  };
  return (
    <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      {/* <Paper sx={{ flexGrow: 1 }}> */}
      <Typography variant="h5">{`New Service Option`}</Typography>
      <br />
      <div>
        <TextField
          fullWidth
          size="small"
          value={newService?.name}
          label="Name"
          variant="outlined"
          onChange={(e) => {
            setNewService((pre) => ({
              ...pre,
              name: e?.target?.value,
            }));
          }}
          style={{ width: "100%", marginBottom: 15 }}
        />
        <div>
          <FormLabel>Delivery Time</FormLabel>
          <div style={{ display: "flex", marginTop: 5 }}>
            <TextField
              fullWidth
              size="small"
              value={newService?.deliveryTime?.days ?? ""}
              // label="Delivery Time"
              variant="outlined"
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setNewService((pre) => ({
                    ...pre,
                    deliveryTime: {
                      ...pre?.deliveryTime,
                      days: e?.target?.value,
                    },
                  }));
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment>Days</InputAdornment>,
              }}
              style={{ width: "100%", marginBottom: 15, marginRight: 3 }}
            />
            <TextField
              fullWidth
              size="small"
              value={newService?.deliveryTime?.hours ?? ""}
              // label="Delivery Time"
              variant="outlined"
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  (regex.test(e.target.value) &&
                    parseInt(e?.target?.value) < 24)
                ) {
                  setNewService((pre) => ({
                    ...pre,
                    deliveryTime: {
                      ...pre?.deliveryTime,
                      hours: e?.target?.value,
                    },
                  }));
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment>Hours</InputAdornment>,
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "colmn",
            // justifyContent: "space-between",
          }}
        >
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            onClick={onSubmit}
          >
            Submit
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
      {/* </Paper> */}
    </Box>
  );
}
