import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { addGlobalUser } from "API/GlobalUsers";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { getSurchargeSets } from "Components/Pricing/SurchargeSets/functions";
import { getPricingLists } from "Components/Pricing/functions";
import { VishcorpContext } from "Context";
import AddressInput from "Templates/UI/Input/AddressInput";
import Email from "Templates/UI/Input/Email";
import Phone from "Templates/UI/Input/Phone";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function AddGlobalUser(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const { handleClose, refreshGlobalUsers } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [globalUserDetails, setGlobalUserDetails] = useState({
    globalUserName: "",
    phone: "",
    address: "",
    email: "",
    surchargeList: "",
    pricingList: "",
  });

  const [confirmAddGlobalUser, setConfirmAddGlobalUser] = useState(false);

  const [pricingLists, setPricingLists] = useState(undefined);
  const [surchargeSets, setSurchargeSets] = useState(undefined);

  useEffect(() => {
    (async () => {
      await getPricingLists(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setPricingLists(res);
        })
        .catch(console.error);
      await getSurchargeSets(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setSurchargeSets(res);
        })
        .catch(console.error);
    })();
  }, [orgSettings]);

  return (
    <div>
      <br />
      <TextField
        fullWidth
        error={errors?.globalUserName && true}
        helperText={errors?.globalUserName?.message}
        {...register("globalUserName", {
          required: "Required*",
        })}
        label="Name"
        value={globalUserDetails?.globalUserName}
        onChange={(e) =>
          setGlobalUserDetails((pre) => {
            return { ...pre, globalUserName: e?.target?.value };
          })
        }
        variant="outlined"
        size="small"
      />
      <Phone
        errors={errors}
        required={true}
        register={register}
        style={{ marginTop: 10 }}
        value={globalUserDetails?.phone}
        setValue={(e) => {
          setGlobalUserDetails((pre) => {
            return { ...pre, phone: e };
          });
        }}
        label={"Phone"}
      />
      <Email
        required={true}
        register={register}
        errors={errors}
        style={{ marginTop: 10 }}
        value={globalUserDetails?.email}
        setValue={(e) => {
          setGlobalUserDetails((pre) => {
            return { ...pre, email: e };
          });
        }}
        label={"Email"}
      />
      <AddressInput
        register={register}
        errors={errors}
        required={true}
        style={{ marginTop: 10 }}
        value={globalUserDetails?.address}
        setValue={(e) => {
          setGlobalUserDetails((pre) => {
            return { ...pre, address: e };
          });
        }}
        label={"Address"}
      />
      <FormControl
        fullWidth
        style={{ width: "100%", marginBottom: 15 }}
        size="small"
      >
        <InputLabel id="price-list-label">Price List</InputLabel>
        <Select
          {...register("pricingList", {
            required: "Required*",
          })}
          error={errors?.pricingList && true}
          labelId="price-list-label"
          label={"Price List"}
          fullWidth
          value={globalUserDetails?.pricingList ?? ""}
          onChange={(e) => {
            setGlobalUserDetails((pre) => {
              return { ...pre, pricingList: e?.target?.value };
            });
          }}
        >
          {pricingLists?.length > 0 &&
            pricingLists?.map((e, i) => (
              <MenuItem value={e?.pricingListName} key={i}>
                {e?.pricingListName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        style={{ width: "100%", marginBottom: 15 }}
        size="small"
      >
        <InputLabel id="surcharge-list-label">Surcharge List</InputLabel>
        <Select
          {...register("surchargeSet", {
            required: "Required*",
          })}
          error={errors?.surchargeSet && true}
          labelId="surcharge-list-label"
          label={"Surcharge List"}
          fullWidth
          value={globalUserDetails?.surchargeSet ?? ""}
          onChange={(e) => {
            setGlobalUserDetails((pre) => {
              return { ...pre, surchargeSet: e?.target?.value };
            });
          }}
        >
          {surchargeSets?.length > 0 &&
            surchargeSets?.map((e, i) => (
              <MenuItem value={e?.surchargeSetName} key={i}>
                {e?.surchargeSetName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button
        sx={{
          borderRadius: 0,
        }}
        variant="contained"
        onClick={handleSubmit(() => {
          setConfirmAddGlobalUser(true);
        })}
      >
        Add Global User
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: 10 }}
        sx={{
          borderRadius: 0,
        }}
        color="error"
        onClick={() => {
          handleClose();
        }}
      >
        Cancel
      </Button>
      <AlertDialog
        open={confirmAddGlobalUser}
        handleClose={() => {
          setConfirmAddGlobalUser(false);
        }}
        handleConfirm={async () => {
          addGlobalUser(
            globalUserDetails,
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          ).then((res) => {
            setConfirmAddGlobalUser(false);
            refreshGlobalUsers();
            // handleClose();
          });
        }}
        dialogTitle="Confirm Add Global User"
      />
    </div>
  );
}
