import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { primaryButtonHoverColor } from "constants";
import { primaryButtonColor } from "constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AttributeTypes } from "../constants";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function NewAttribute(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const { handleClose, handleUpdate, isOrder } = props;

  const [attributeDetails, setAttributeDetails] = useState({
    defaultValue: undefined,
    required: true,
    name: undefined,
    type: "String",
    group: isOrder ? "Pick Up" : undefined,
    visible: true,
  });

  const [addOption, setAddOption] = useState(false);
  const [confirmNewAttribute, setConfirmNewAttribute] = useState(false);
  const [addOptionData, setAddOptionData] = useState(undefined);
  const [optionsRequired, setOptionsRequired] = useState(false);

  useEffect(() => {
    if (["Options", "Radio", "Checkbox"].includes(attributeDetails?.type)) {
      setOptionsRequired(true);
    } else {
      setOptionsRequired(false);
    }
    if (optionsRequired && !(attributeDetails?.options?.length > 0)) {
      setError("type", {});
    } else {
      clearErrors("type");
    }
  }, [attributeDetails, optionsRequired, setError, clearErrors]);

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <TextField
        error={errors?.name && true}
        helperText={errors?.name?.message}
        {...register("name", {
          required: "Required*",
        })}
        size="small"
        label="Name"
        variant="outlined"
        value={attributeDetails?.name ?? ""}
        onChange={(e) => {
          setAttributeDetails((preVal) => ({
            ...preVal,
            name: e?.target?.value,
            id: String(e?.target?.value)
              ?.split(" ")
              ?.map((v, i) =>
                i === 0 ? String(v).toLowerCase() : capitalizeFirstLetter(v)
              )
              ?.join(""),
          }));
        }}
        style={{ width: "100%", marginBottom: 15 }}
      />
      <FormControl
        fullWidth
        style={{ width: "100%", marginBottom: 15 }}
        size="small"
      >
        <InputLabel id="price-list-label">Type</InputLabel>
        <Select
          fullWidth
          value={attributeDetails?.type}
          size="small"
          label="Type"
          onChange={(e) => {
            setAttributeDetails((preVal) => ({
              ...preVal,
              type: e?.target?.value,
            }));
          }}
          sx={{ marginBottom: 1 }}
        >
          {AttributeTypes?.map((e, i) => (
            <MenuItem value={e} key={i}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {optionsRequired && (
        <div>
          <Card>
            <CardContent>
              <Button
                variant="contained"
                size="small"
                sx={{
                  borderRadius: 10,
                  backgroundColor: primaryButtonColor,
                  marginBottom: 1,
                  "&:hover": {
                    backgroundColor: primaryButtonHoverColor,
                  },
                }}
                onClick={() => {
                  setAddOption(true);
                }}
              >
                Add Option for Attribute
              </Button>
              {attributeDetails?.options?.length > 0 ? (
                <Grid container spacing={1}>
                  <br />
                  {attributeDetails?.options?.map((e, i) => (
                    <Grid item xs={3} key={`GridItem${i}`}>
                      <Chip
                        label={e}
                        onDelete={() => {
                          setAttributeDetails((preVal) => ({
                            ...preVal,
                            options: preVal?.options?.filter(
                              (el, ind) => ind !== i
                            ),
                          }));
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div>
                  <br />
                  <FormLabel sx={{ display: "flex", justifyContent: "center" }}>
                    Option List Empty
                  </FormLabel>
                </div>
              )}
            </CardContent>
          </Card>
          {errors?.type && (
            <p style={{ color: "#ba000d" }}>Options cannot be empty*</p>
          )}
          <br />
        </div>
      )}
      {!optionsRequired && (
        <TextField
          size="small"
          label="Default Value"
          variant="outlined"
          value={attributeDetails?.defaultValue ?? ""}
          onChange={(e) => {
            setAttributeDetails((preVal) => ({
              ...preVal,
              defaultValue: e?.target?.value,
            }));
          }}
          style={{ width: "100%", marginBottom: 15 }}
        />
      )}
      {isOrder && (
        <FormControl
          fullWidth
          style={{ width: "100%", marginBottom: 15 }}
          size="small"
        >
          <InputLabel id="price-list-label">Group</InputLabel>
          <Select
            fullWidth
            value={attributeDetails?.group}
            size="small"
            defaultValue="Pick Up"
            label="Group"
            onChange={(e) => {
              setAttributeDetails((preVal) => ({
                ...preVal,
                group: e?.target?.value,
              }));
            }}
            sx={{ marginBottom: 1 }}
          >
            <MenuItem value={"Pick Up"} key={"pickUp"}>
              Pick Up
            </MenuItem>
            <MenuItem value={"Deliver To"} key={"deliverTo"}>
              Deliver To
            </MenuItem>
            <MenuItem value={"Parcel Details"} key={"parcelDetails"}>
              Parcel Details
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Required</FormLabel>
        <RadioGroup
          row
          value={attributeDetails?.required}
          onChange={(e) => {
            setAttributeDetails((preVal) => ({
              ...preVal,
              required: e?.target?.value === "true" ?? false,
            }));
          }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "right",
          // alignContent: "right",
        }}
        spacing={1}
      >
        <Grid item>
          <Button
            sx={{ borderRadius: 0 }}
            variant="contained"
            onClick={handleSubmit(() => {
              setConfirmNewAttribute(true);
            })}
          >
            Update
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ borderRadius: 0 }}
            variant="contained"
            color="error"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <AlertDialog
        open={addOption}
        dialogTitle="Attribute Option"
        handleClose={() => {
          setAddOption(false);
          setAddOptionData("");
        }}
        handleConfirm={() => {
          setAttributeDetails((preVal) => ({
            ...preVal,
            options: [...(preVal?.options ?? []), addOptionData],
          }));
          setAddOption(false);
          setAddOptionData("");
        }}
        dialogContent={
          <Box>
            <br />
            <TextField
              fullWidth
              size="small"
              label="Option"
              value={addOptionData ?? ""}
              onChange={(e) => {
                setAddOptionData(e?.target?.value);
              }}
            />
          </Box>
        }
      />
      <AlertDialog
        open={confirmNewAttribute}
        handleClose={() => {
          setConfirmNewAttribute(false);
        }}
        handleConfirm={() => {
          handleUpdate(attributeDetails);
          setConfirmNewAttribute(false);
          handleClose();
        }}
        dialogTitle=""
        dialogContent={
          <FormLabel>
            <b>Confirm Add New Attribute: {attributeDetails?.name}</b>
          </FormLabel>
        }
      />
    </Box>
  );
}
