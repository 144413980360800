export const getCustomerInvoices = async (
  startDate,
  endDate,
  organizationID,
  organizationTablePrefix
) => {
  return fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/invoices",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ALL_CUSTOMER_INVOICES",
        startDate,
        endDate,
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getInvoiceByCustomer = async (
  startDate,
  endDate,
  customerID,
  organizationID,
  organizationTablePrefix
) => {
  return fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/invoices",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_CUSTOMER_INVOICE",
        startDate,
        endDate,
        customerID,
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getAllDriverCommissions = async (
  startDate,
  endDate,
  organizationID,
  organizationTablePrefix
) => {
  return fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/invoices",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ALL_DRIVER_INVOICES",
        startDate,
        endDate,
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getDriverCommissions = async (
  startDate,
  endDate,
  driverID,
  organizationID,
  organizationTablePrefix
) => {
  return fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/invoices",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_DRIVER_INVOICES",
        startDate,
        endDate,
        organizationID,
        organizationTablePrefix,
        driverID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateInvoiceSettings = async (
  invoiceSettings,
  organizationID,
  organizationName
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_INVOICE_SETTINGS",
        invoiceSettings,
        organizationID,
        organizationName,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
