import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { VishcorpSettingsContext } from "Components/CompanySettings/context";
import { useContext, useEffect, useState } from "react";
import { VishcorpSurchargeContext } from "./context";

export default function AddFuelCharge(props) {
  const { surchargeSetDetails } = useContext(VishcorpSurchargeContext);
  const { onSaveFuelCharges, handleClose } = props;
  const { organizationSettings } = useContext(VishcorpSettingsContext);

  const [selectedVehicleType, setSelectedVehicleType] = useState(undefined);
  const [selectedPackageType, setSelectedPackageType] = useState(undefined);
  const [selectedServiceOption, setSelectedServiceOption] = useState(undefined);

  const [fuelCharge, setFuelCharge] = useState({
    chargeType: undefined,
    value: undefined,
    overrideDriverCommission: false,
  });

  const { packageTypes, serviceOptions, vehicleTypes } = organizationSettings;

  const fuelCharges = surchargeSetDetails?.fuelCharges;

  useEffect(() => {
    if (
      fuelCharges &&
      selectedVehicleType &&
      selectedPackageType &&
      selectedServiceOption
    ) {
      setFuelCharge(
        fuelCharges?.[selectedServiceOption]?.[selectedPackageType]?.[
          selectedVehicleType
        ]
      );
    }
  }, [
    fuelCharges,
    selectedVehicleType,
    selectedPackageType,
    selectedServiceOption,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Add Fuel Charge</Typography>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FormControl
            fullWidth
            style={{ width: "100%", marginRight: 10 }}
            size="small"
          >
            <InputLabel id="price-list-label">Apply to Service</InputLabel>
            <Select
              fullWidth
              value={selectedServiceOption ?? ""}
              size="small"
              label="Apply to Service"
              onChange={(e) => {
                setSelectedServiceOption(e?.target?.value);
              }}
            >
              {serviceOptions?.length > 0 &&
                [{ name: "All Services" }, ...serviceOptions]?.map((e, i) => (
                  <MenuItem key={i} value={e?.name}>
                    {e?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{ width: "100%", marginRight: 10 }}
            size="small"
          >
            <InputLabel id="price-list-label">Apply to Package type</InputLabel>
            <Select
              fullWidth
              value={selectedPackageType ?? ""}
              size="small"
              label="Apply to Package type"
              onChange={(e) => {
                setSelectedPackageType(e?.target?.value);
              }}
            >
              {packageTypes?.length > 0 &&
                [{ name: "All Package Types" }, ...packageTypes]?.map(
                  (e, i) => (
                    <MenuItem key={i} value={e?.name}>
                      {e?.name}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ width: "100%" }} size="small">
            <InputLabel id="price-list-label">Apply to Vehicle type</InputLabel>
            <Select
              fullWidth
              value={selectedVehicleType ?? ""}
              size="small"
              label="Apply to Vehicle type"
              onChange={(e) => {
                setSelectedVehicleType(e?.target?.value);
              }}
            >
              {vehicleTypes?.length > 0 &&
                [{ name: "All Vehicle Types" }, ...vehicleTypes]?.map(
                  (e, i) => (
                    <MenuItem key={i} value={e?.name}>
                      {e?.name}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div></div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <FormControl>
            <RadioGroup
              value={fuelCharge?.chargeType ?? ""}
              onChange={(e) =>
                setFuelCharge((pre) => ({
                  ...pre,
                  chargeType: e?.target?.value,
                  value:
                    e?.target?.value ===
                    fuelCharges?.[selectedServiceOption]?.[
                      selectedPackageType
                    ]?.[selectedVehicleType]?.chargeType
                      ? fuelCharges?.[selectedServiceOption]?.[
                          selectedPackageType
                        ]?.[selectedVehicleType]?.value
                      : undefined,
                  overrideDriverCommission:
                    e?.target?.value ===
                    fuelCharges?.[selectedServiceOption]?.[
                      selectedPackageType
                    ]?.[selectedVehicleType]?.chargeType
                      ? fuelCharges?.[selectedServiceOption]?.[
                          selectedPackageType
                        ]?.[selectedVehicleType]?.overrideDriverCommission
                      : false,
                }))
              }
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="distanceCharge"
                  control={<Radio />}
                  label="Distance Charge"
                />
                {fuelCharge?.chargeType === "distanceCharge" && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      size="small"
                      value={fuelCharge?.value ?? ""}
                      label="Price/km"
                      variant="outlined"
                      onChange={(e) => {
                        const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
                        // const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          setFuelCharge((pre) => ({
                            ...pre,
                            value: e?.target?.value,
                          }));
                        }
                      }}
                      style={{ width: "100%", marginBottom: 15 }}
                    />
                    <FormControlLabel
                      sx={{ marginLeft: 5 }}
                      control={
                        <Checkbox
                          checked={fuelCharge?.overrideDriverCommission}
                          onChange={(e) => {
                            setFuelCharge((pre) => ({
                              ...pre,
                              overrideDriverCommission: e?.target?.checked,
                            }));
                          }}
                        />
                      }
                      label="Driver Commission Override"
                    />
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="percentageBase"
                  control={<Radio />}
                  label="Percentage of base price"
                />
                {fuelCharge?.chargeType === "percentageBase" && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      size="small"
                      value={fuelCharge?.value ?? ""}
                      label="Percentage"
                      variant="outlined"
                      onChange={(e) => {
                        const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
                        // const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          setFuelCharge((pre) => ({
                            ...pre,
                            value: e?.target?.value,
                          }));
                        }
                      }}
                      style={{ width: "100%", marginBottom: 15 }}
                    />
                    <FormControlLabel
                      sx={{ marginLeft: 5 }}
                      control={
                        <Checkbox
                          checked={fuelCharge?.overrideDriverCommission}
                          onChange={(e) =>
                            setFuelCharge((pre) => ({
                              ...pre,
                              overrideDriverCommission: e?.target?.checked,
                            }))
                          }
                        />
                      }
                      label="Driver Commission Override"
                    />
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="percentageTotal"
                  control={<Radio />}
                  label="Percentage of total price"
                />
                {fuelCharge?.chargeType === "percentageTotal" && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      size="small"
                      value={fuelCharge?.value ?? ""}
                      label="Percentage"
                      variant="outlined"
                      onChange={(e) => {
                        const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
                        // const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          setFuelCharge((pre) => ({
                            ...pre,
                            value: e?.target?.value,
                          }));
                        }
                      }}
                      style={{ width: "100%", marginBottom: 15 }}
                    />
                    <FormControlLabel
                      sx={{ marginLeft: 5 }}
                      control={
                        <Checkbox
                          checked={fuelCharge?.overrideDriverCommission}
                          onChange={(e) =>
                            setFuelCharge((pre) => ({
                              ...pre,
                              overrideDriverCommission: e?.target?.checked,
                            }))
                          }
                        />
                      }
                      label="Driver Commission Override"
                    />
                  </div>
                )}
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onSaveFuelCharges({
                ...(fuelCharges ?? {}),
                [selectedServiceOption]: {
                  ...(fuelCharges?.[selectedServiceOption] ?? {}),
                  [selectedPackageType]: {
                    ...(fuelCharges?.[selectedServiceOption]?.[
                      selectedPackageType
                    ] ?? {}),
                    [selectedVehicleType]: fuelCharge,
                  },
                },
              });
              handleClose();
            }}
            disabled={
              !(
                selectedPackageType &&
                selectedServiceOption &&
                selectedVehicleType
              )
            }
          >
            Save and Close
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClose()}
          >
            Exit
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
