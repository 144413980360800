import { Grid } from "@mui/material";
import { VishcorpContext } from "Context";
import { useContext } from "react";
import DriverButton from "./DriverButton";

export default function DriverPanel(props) {
  // const [checked, setChecked] = useState(false);
  // const [drivers, setDrivers] = useState(undefined);
  const { selectedDriver, setSelectedDriver, drivers } =
    useContext(VishcorpContext);
  const allDriverOrderCount = drivers?.reduce(
    (acc, e) => (e?.driverOrderCount ?? 0) + acc,
    0
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      <div style={{ marginTop: 100 }}></div>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <DriverButton
          driverInfo={{
            driverNumber: undefined,
            driverName: "All",
            driverOrderCount: allDriverOrderCount,
          }}
          onClick={() => {
            setSelectedDriver(undefined);
          }}
          active={undefined === selectedDriver}
        />
      </Grid>

      {drivers &&
        drivers?.map((e, i) => (
          <Grid key={i} item xs={4} sm={4} md={4} lg={4} xl={4}>
            <DriverButton
              driverInfo={e}
              onClick={() => {
                if (e?.driverNumber === selectedDriver) {
                  setSelectedDriver(undefined);
                } else {
                  setSelectedDriver(e?.driverNumber);
                }
              }}
              active={e?.driverNumber === selectedDriver}
            />
          </Grid>
        ))}
    </Grid>
  );
}
